import React from 'react';
import styled from 'styled-components';
import {MDBContainer, MDBRow, MDBCol} from 'mdb-react-ui-kit';
import Breadcrumb from '../../../components/Breadcrumb';
import ContactBar from '../../../components/ContactBar';
import TitleImage from '../../../components/TitleImage';
import image from '../../../assets/images/infrastructure.jpg';
import imageLong from '../../../assets/images/infrastructure-long.jpg';
import suppliersIcon from '../../../assets/icons/suppliers.png';
import systemControlsIcon from '../../../assets/icons/system-controls.png';
import dataCaptureIcon from '../../../assets/icons/data-capture.png';
import securityIcon from '../../../assets/icons/security.png';

const Styles = styled.div`
`;

export default function Infrastructure() {
    const title = 'Infrastructure';
    const items = [{name: 'Services', link: '/services'},
        {name: 'Audit', link: '/services/audit'}];

    return (
        <Styles>
            <MDBContainer fluid className="px-0 overflow-hidden">
                <TitleImage image={image} title={title} backgroundPositionY="37%"/>
                <Breadcrumb items={items} active={title}/>
                <MDBRow>
                    <MDBCol size="12">
                        <div className="m-5">
                            <h3><b>Foundation to Success</b></h3>
                            <p>A solid infrastructure creates a reliable foundation upon which to build your business
                                and is crucial for you to maintain your competitive edge in the market. By leveraging
                                our expertise in software development, policy-making, and bespoke services, we can help
                                you identify the best practices and strategies to differentiate yourself from your
                                competitors and achieve long-term success.</p>
                            <p>From your supplier network to your technology platforms, we can ensure you can trust the
                                services that underpin your operations.</p>
                        </div>
                    </MDBCol>
                </MDBRow>
                <MDBRow className="grey-row">
                    <MDBCol size="3" className="d-md-flex d-none">
                        <img src={imageLong} alt="infrastructure" className="align-top vertical-image"/>
                    </MDBCol>
                    <MDBCol size="1" className="my-3 d-md-flex d-none justify-content-center vertical-title">
                        <div className="text-center">Services</div>
                    </MDBCol>
                    <MDBCol sm="12" md="8" id="service-icons" className="my-3">
                        <MDBRow>
                            <MDBCol size="3" md="2" className="d-flex align-items-center justify-content-center pe-0">
                                <img src={suppliersIcon} alt="suppliers"/>
                            </MDBCol>
                            <MDBCol size="9" md="10">
                                <div className="m-4">
                                    <h4>Suppliers</h4>
                                    <p>Your suppliers should be a seamless extension of your business. Their actions
                                        reflect on your reputation, so it is imperative they work to the same standards
                                        you demand internally. We ensure external TPAs understand, follow, and actively
                                        promote your own ideals knitting themselves to your processes to eliminate
                                        unnecessary disconnects.</p>
                                </div>
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol size="3" md="2" className="d-flex align-items-center justify-content-center pe-0">
                                <img src={systemControlsIcon} alt="system-controls"/>
                            </MDBCol>
                            <MDBCol size="9" md="10">
                                <div className="m-4">
                                    <h4>System Controls</h4>
                                    <p>Effective controls limit your exposure to financial and legal risks and your
                                        internal systems play a key role in defining, monitoring, and ultimately
                                        enforcing them. Our combined experience on claims procedures and software
                                        development allows us a unique perspective to help shape your platforms and
                                        ensure you run a tight ship.</p>
                                </div>
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol size="3" md="2" className="d-flex align-items-center justify-content-center pe-0">
                                <img src={dataCaptureIcon} alt="data-capture"/>
                            </MDBCol>
                            <MDBCol size="9" md="10">
                                <div className="m-4">
                                    <h4>Data Capture</h4>
                                    <p>Solid data compliance is a duty that every reputable business must strive to
                                        uphold. We ensure that your data holding policies strike the correct balance
                                        between functional use and legal adherence while providing guidance on best
                                        system practices, data leakage prevention and retention.</p>
                                </div>
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol size="3" md="2" className="d-flex align-items-center justify-content-center pe-0">
                                <img src={securityIcon} alt="security"/>
                            </MDBCol>
                            <MDBCol size="9" md="10">
                                <div className="m-4">
                                    <h4>Security</h4>
                                    <p>Your business is only as strong as your weakest point of entry and security
                                        breaches can be a costly affair to both your finances and your reputation.
                                        Making sure you follow the latest practices, employ up to date security
                                        platforms, and train your staff to be vigilant against threats is all part of
                                        the service.</p>
                                </div>
                            </MDBCol>
                        </MDBRow>
                    </MDBCol>
                </MDBRow>
                <ContactBar/>
            </MDBContainer>
        </Styles>
    )
}
