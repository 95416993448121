import React from 'react';
import styled from 'styled-components';
import {
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBCard,
    MDBCardImage,
    MDBCardHeader,
    MDBCardBody,
    MDBCardText, MDBCardLink,
} from 'mdb-react-ui-kit';
import Breadcrumb from '../../components/Breadcrumb';
import TitleImage from '../../components/TitleImage';
import services from '../../assets/data/auditServices';
import image from '../../assets/images/audit.jpg';

const Styles = styled.div`
`;

export default function Audit() {
    const title = 'Audit';
    const items = [{name: 'Services', link: '/services'}];

    return (
        <Styles>
            <MDBContainer fluid className="px-0 overflow-hidden">
                <TitleImage image={image} title={title} backgroundPositionY="68%"/>
                <Breadcrumb items={items} active={title}/>
                <MDBRow>
                    <MDBCol size="12">
                        <div className="m-5">
                            <h3><b>Raising the Bar</b></h3>
                            <p>Regular and proactive examination of your operation is key to avoiding stagnation and
                                driving meaningful change throughout. Through our audit offering we can provide an
                                independent and unbiased assessment of your claims processes, whether you operate
                                internally or outsource to external TPAs. Our experienced auditors have a deep
                                understanding of the motor claims market and can provide you with the insights and
                                recommendations you need to optimize your strategy and improve your bottom line.</p>
                            <p>Highly versed across a wide range of services we can deliver anything from a full
                                assessment of your total claims offering to a tailored deep dive into a specific key
                                concern.</p>
                            <br/>
                            <h5>Select a service below to find out more:</h5>
                        </div>
                    </MDBCol>
                </MDBRow>
                <MDBRow>
                    <MDBCol size="12">
                        <div className="ms-5 me-5 mb-5">
                            <MDBRow className="row-cols-1 row-cols-sm-2 row-cols-md-3 ">
                                {services.map((service, index) => {
                                    return (
                                        <MDBCol key={index} className="my-2">
                                            <MDBCard className="h-100">
                                                <MDBCardImage src={service.image} alt={service.name} position="top"
                                                              style={{
                                                                  height: 150,
                                                                  objectPosition: service.objectPosition,
                                                              }}/>
                                                <MDBCardHeader>{service.name}</MDBCardHeader>
                                                <MDBCardBody>
                                                    <MDBCardText>
                                                        {service.information}
                                                    </MDBCardText>
                                                    <MDBCardLink href={service.link}>Find out more ></MDBCardLink>
                                                </MDBCardBody>
                                            </MDBCard>
                                        </MDBCol>
                                    )
                                })}
                            </MDBRow>
                        </div>
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
        </Styles>
    )
}
