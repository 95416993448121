import React from 'react';
import styled from 'styled-components';
import {
    MDBContainer, MDBCard, MDBCardBody, MDBCardSubTitle, MDBCardText, MDBCardImage, MDBRow,
    MDBCol,
} from 'mdb-react-ui-kit';
import members from '../assets/data/teamMembers';
import Breadcrumb from '../components/Breadcrumb';
import TitleImage from '../components/TitleImage';
import image from '../assets/images/about.jpg';

const Styles = styled.div`
    .card {
        img {
            height: 290px;
            object-fit: contain;
            background-color: var(--aac-blue);
            filter: grayscale(100%);

            &:hover {
                filter: grayscale(0%);
            }
        }

        .card-body {
            p.muted {
                color: var(--mdb-gray);
                font-size: 0.8rem;
            }
        }
    }
`;

export default function About() {
    const title = 'About';

    return (
        <Styles>
            <MDBContainer fluid className="px-0 overflow-hidden">
                <TitleImage image={image} title={title}/>
                <Breadcrumb active={title}/>
                <MDBRow>
                    <MDBCol size="12">
                        <div className="m-5">
                            <p>Founded in 2000 in the seaside town of Southend-On-Sea, Auto Accident Claims entered the
                                industry supporting self-insuring fleets and niche insurance providers. Working closely
                                with our fleet operator clients, we recognised providing a bespoke service which
                                minimised vehicle downtime, enabled our clients to steal a march on their competitors.
                                This innovative approach of taking time to fully understand a client's requirements
                                enabled us to become experts in the provision of accident and claims management services
                                and opened doors into the motor insurance market.</p>
                            <p>From there we enjoyed steady business development through client recommendation and
                                retention and grew into the enterprise that we are today providing full claims services
                                to insurers, nationwide fleets, and MGAs.</p>
                            <p>Passionate for the delivery of quality and professional accident management services we
                                are proud of the relationships we have grown and are excited to build new ones as we
                                invest in the future of our expanding portfolio.</p>
                        </div>
                    </MDBCol>
                </MDBRow>
                {/*<MDBRow className="grey-container">*/}
                {/*    <MDBCol size="12">*/}
                {/*        <div className="m-5">*/}
                {/*            <h3>Meet the Executive Team</h3>*/}
                {/*            <MDBRow className="row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4">*/}
                {/*                {members.map((member, index) => {*/}
                {/*                    return (*/}
                {/*                        <MDBCol key={index} className="my-2">*/}
                {/*                            <MDBCard className="h-100">*/}
                {/*                                <MDBCardImage src={member.image} alt={member.name} position="top"/>*/}
                {/*                                <MDBCardBody>*/}
                {/*                                    <MDBCardSubTitle>{member.name}</MDBCardSubTitle>*/}
                {/*                                    <MDBCardText className="muted">*/}
                {/*                                        {member.title}*/}
                {/*                                    </MDBCardText>*/}
                {/*                                </MDBCardBody>*/}
                {/*                            </MDBCard>*/}
                {/*                        </MDBCol>*/}
                {/*                    )*/}
                {/*                })}*/}
                {/*            </MDBRow>*/}
                {/*        </div>*/}
                {/*    </MDBCol>*/}
                {/*</MDBRow>*/}
            </MDBContainer>
        </Styles>
    )
}
