import React, {Component} from 'react';
import {BrowserRouter, Routes, Route, Navigate} from 'react-router-dom';
import styled from 'styled-components';
import {Home} from './views/Home';
import About from './views/About';
import Contact from './views/Contact';
import Accessibility from './views/Accessibility';
import PrivacyPolicy from './views/PrivacyPolicy';
import Complaints from './views/Complaints';
import Terms from './views/Terms';
import Services from './views/Services';
import MotorClaims from './views/services/MotorClaims'
import MotorFirstNotificationOfLoss from './views/services/motorClaims/FirstNotificationOfLoss';
import RepairManagement from './views/services/motorClaims/RepairManagement';
import Engineering from './views/services/motorClaims/Engineering';
import ClaimsHandling from './views/services/motorClaims/ClaimsHandling';
import ThirdPartyIntervention from './views/services/motorClaims/ThirdPartyIntervention';
import Technology from './views/services/Technology';
import ClaimsManagementSystem from './views/services/technology/ClaimsManagementSystem';
import ManagementInformation from './views/services/technology/ManagementInformation';
import Communication from './views/services/technology/Communication';
import Audit from './views/services/Audit';
import Operations from './views/services/audit/Operations';
import Infrastructure from './views/services/audit/Infrastructure';
import QualityAndCompliance from './views/services/audit/QualityAndCompliance';
import {Layout} from './components/Layout';
import Header from './components/Header';
import ImageCarousel from './components/ImageCarousel';
import {Footer} from './components/Footer';
import './assets/scss/colours.scss';
import './assets/scss/fonts.scss';
import './assets/scss/main.scss';

const Styles = styled.div`
    .site {
        display: flex;
        min-height: 100vh;
        flex-direction: column;
    }

    .site-content {
        flex: 1;
        margin-top: 64px;
    }
`;

class App extends Component {
    render() {
        return (
            <Styles>
                <div className="site">
                    <React.Fragment>
                        <Header/>
                        <div className="site-content">
                            <BrowserRouter>
                                <ImageCarousel/>
                                <Layout>
                                    <Routes>
                                        <Route path="/" element={<Home/>}/>
                                        <Route path="/about" element={<About/>}/>
                                        <Route path="/contact" element={<Contact/>}/>
                                        <Route path="/accessibility" element={<Accessibility/>}/>
                                        <Route path="/privacypolicy" element={<PrivacyPolicy/>}/>
                                        <Route path="/complaints" element={<Complaints/>}/>
                                        <Route path="/terms" element={<Terms/>}/>
                                        <Route path="/services" element={<Services/>}/>
                                        <Route path="/services/motor-claims" element={<MotorClaims/>}/>
                                        <Route path="/services/motor-claims/first-notification-of-loss"
                                               element={<MotorFirstNotificationOfLoss/>}/>
                                        <Route path="/services/motor-claims/repair-management"
                                               element={<RepairManagement/>}/>
                                        <Route path="/services/motor-claims/engineering" element={<Engineering/>}/>
                                        <Route path="/services/motor-claims/claims-handling"
                                               element={<ClaimsHandling/>}/>
                                        <Route path="/services/motor-claims/third-party-intervention"
                                               element={<ThirdPartyIntervention/>}/>
                                        <Route path="/services/technology" element={<Technology/>}/>
                                        <Route path="/services/technology/claims-management-system"
                                               element={<ClaimsManagementSystem/>}/>
                                        <Route path="/services/technology/management-information"
                                               element={<ManagementInformation/>}/>
                                        <Route path="/services/technology/communication"
                                               element={<Communication/>}/>
                                        <Route path="/services/audit" element={<Audit/>}/>
                                        <Route path="/services/audit/operations" element={<Operations/>}/>
                                        <Route path="/services/audit/infrastructure" element={<Infrastructure/>}/>
                                        <Route path="/services/audit/quality-and-compliance"
                                               element={<QualityAndCompliance/>}/>
                                        <Route path="*" element={<Navigate replace to="/"/>}/>
                                    </Routes>
                                </Layout>
                            </BrowserRouter>
                        </div>
                        <Footer/>
                    </React.Fragment>
                </div>
            </Styles>
        );
    }
}

export default App;
