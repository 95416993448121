import React from 'react';
import styled from 'styled-components';

const Styles = styled.div`
    .bg-image {
        // filter: brightness(80%);
        border-bottom: 3px solid var(--aac-blue);
    }
  
    .page-title {
        color: white;
        background: linear-gradient(58deg, #085DCF 84%, transparent 0%);
    
        h1 {
            padding: 0.3rem 3rem;
            margin-right: 4rem;
            text-align: left;
        }
    }
`;

function TitleImage({image, title, backgroundPositionY}) {
    return (
        <Styles>
            <div className="p-5 text-center bg-image"
                 style={{backgroundImage: `url(${image})`, height: 300, backgroundPositionY: backgroundPositionY}}>
                <div className="mask" style={{backgroundColor: 'rgba(0, 0, 0, 0.1)'}}>
                    <div className="d-flex justify-content-md-start align-items-end h-100">
                        <div className="page-title">
                            <h1>{title}</h1>
                        </div>
                    </div>
                </div>
            </div>
        </Styles>
    );
}

export default TitleImage;
