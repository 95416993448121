import React from 'react';
import styled from 'styled-components';
import {MDBContainer, MDBRow, MDBCol} from 'mdb-react-ui-kit';
import Breadcrumb from '../../../components/Breadcrumb';
import ContactBar from '../../../components/ContactBar';
import TitleImage from '../../../components/TitleImage';
import image from '../../../assets/images/communication.jpg';
import imageLong from '../../../assets/images/communication-long.jpg';
import telephonyIcon from '../../../assets/icons/telephony.png';
import portalIcon from '../../../assets/icons/portal.png';
import messagingIcon from '../../../assets/icons/messaging.png';
import fileSharingIcon from '../../../assets/icons/file-sharing.png';

const Styles = styled.div`
`;

export default function Communication() {
    const title = 'Communication';
    const items = [{name: 'Services', link: '/services'},
        {name: 'Technology', link: '/services/technology'}];

    return (
        <Styles>
            <MDBContainer fluid className="px-0 overflow-hidden">
                <TitleImage image={image} title={title} backgroundPositionY="65%"/>
                <Breadcrumb items={items} active={title}/>
                <MDBRow>
                    <MDBCol size="12">
                        <div className="m-5">
                            <h3><b>Connect With Confidence</b></h3>
                            <p>Strong communication is the most critical foundation of any enterprise. By leveraging the
                                latest IVR, messaging and web-based technologies we offer both outstanding real-time and
                                asynchronous communication experiences for your clients, meeting them through the medium
                                on which they are most comfortable. Through smart routing we ensure that the customer
                                gets through to the right team as quickly as possible, so we spend less time on
                                overheads and more time giving them the quality service they expect.</p>
                            <p>Adopting modern comms-tech has also allowed us to adapt to a changing employment
                                environment taking on an increased number of home workers. This not only makes us more
                                resilient to risks but opens us up to a diverse array of skilled experts giving us the
                                scalability to cope with ever evolving demands.</p>
                        </div>
                    </MDBCol>
                </MDBRow>
                <MDBRow className="grey-row">
                    <MDBCol size="3" className="d-md-flex d-none">
                        <img src={imageLong} alt="communication" className="align-top vertical-image"/>
                    </MDBCol>
                    <MDBCol size="1" className="my-3 d-md-flex d-none justify-content-center vertical-title">
                        <div className="text-center">Services</div>
                    </MDBCol>
                    <MDBCol sm="12" md="8" id="service-icons" className="my-3">
                        <MDBRow>
                            <MDBCol size="3" md="2" className="d-flex align-items-center justify-content-center pe-0">
                                <img src={telephonyIcon} alt="telephony"/>
                            </MDBCol>
                            <MDBCol size="9" md="10">
                                <div className="m-4">
                                    <h4>Telephony</h4>
                                    <p>Our 24/7 fully white labelled contact centre makes use of the latest intelligent
                                        IVR and recording technology. The suite can be customized to provide a range of
                                        options that can help streamline your operations and improve customer
                                        satisfaction. Call recording and flow metrics help us further adapt the user
                                        experience to match your customers’ needs.</p>
                                </div>
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol size="3" md="2" className="d-flex align-items-center justify-content-center pe-0">
                                <img src={portalIcon} alt="portal"/>
                            </MDBCol>
                            <MDBCol size="9" md="10">
                                <div className="m-4">
                                    <h4>Portal</h4>
                                    <p>Accidents can bring considerable distress and inconvenience to your clients. To
                                        minimise further impact, we provide web-based submission solutions to guide
                                        policyholders through the initial FNOL stage. By providing a convenient, no
                                        nonsense point of contact, we encourage speedy reporting, leading to proactive
                                        TP capture and credit hire mitigation.</p>
                                </div>
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol size="3" md="2" className="d-flex align-items-center justify-content-center pe-0">
                                <img src={messagingIcon} alt="messaging"/>
                            </MDBCol>
                            <MDBCol size="9" md="10">
                                <div className="m-4">
                                    <h4>Messaging</h4>
                                    <p>Keeping customers informed improves reputation, reduces complaints, and provides
                                        a positive reduction on overall claim overheads. We appreciate that customers
                                        are not always available over the phone, so we provide updates through their
                                        preferred method of communication whenever possible whether that be email, SMS,
                                        or media messaging.</p>
                                </div>
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol size="3" md="2" className="d-flex align-items-center justify-content-center pe-0">
                                <img src={fileSharingIcon} alt="file-sharing"/>
                            </MDBCol>
                            <MDBCol size="9" md="10">
                                <div className="m-4">
                                    <h4>File Sharing</h4>
                                    <p>Sharing data is key to any multifaceted business especially when operating a
                                        distributed structure with external TPAs. To facilitate smooth communication
                                        between our services and your own, we provide user friendly, customised file
                                        platforms.</p>
                                </div>
                            </MDBCol>
                        </MDBRow>
                    </MDBCol>
                </MDBRow>
                <ContactBar/>
            </MDBContainer>
        </Styles>
    )
}
