import operationsImage from '../images/operations.jpg';
import infrastructureImage from '../images/infrastructure.jpg';
import qualityAndComplianceImage from '../images/quality-and-compliance.jpg';

const auditServices = [
    {
        name: 'Operations',
        image: operationsImage,
        information: 'Constructive end-to-end assessment to mitigate loss and bring genuine value to your total claims offering.',
        link: '/services/audit/operations',
        objectPosition: '0 50%',
    },
    {
        name: 'Infrastructure',
        image: infrastructureImage,
        information: 'Extended analysis of your external suppliers, data capture systems, controls and business information practices.',
        link: '/services/audit/infrastructure',
        objectPosition: '0 50%',
    },
    {
        name: 'Quality & Compliance',
        image: qualityAndComplianceImage,
        information: 'Ensuring your products meet the highest standards of quality and comply with your internal policies and external regulatory requirements.',
        link: '/services/audit/quality-and-compliance',
        objectPosition: '0 50%',
    },
];

export default auditServices;
