import React from 'react';
import Slider from 'react-slick';
import styled from 'styled-components';
import {
    MDBContainer, MDBRow, MDBCol, MDBCard, MDBCardHeader, MDBCardBody, MDBCardText, MDBCardLink,
    MDBCardImage,
} from 'mdb-react-ui-kit';
import Breadcrumb from '../components/Breadcrumb';
import TitleImage from '../components/TitleImage';
import image from '../assets/images/services.jpg';
import motorServices from '../assets/data/motorClaimsServices';
import technologyServices from '../assets/data/technologyServices';
import auditServices from '../assets/data/auditServices';

const Styles = styled.div`
    .services-slick-title {
        padding: 0 3.5rem 0 3.5rem;

        a {
            color: var(--aac-blue);

            &:hover {
                text-decoration: none;
            }
        }
    }
    
    .slick-next:before, .slick-prev:before {
        color: var(--aac-blue)
    }
`;

export default function Services() {
    const title = 'Services';
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
    };
    const responsiveSettings = [
        {
            breakpoint: 992,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
            },
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
            },
        },
        {
            breakpoint: 576,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
            },
        },
    ];
    const smallSettings = {
        ...settings,
        slidesToShow: 3,
        slidesToScroll: 3,
        responsive: [
            ...responsiveSettings,
        ],
    };
    const largeSettings = {
        ...settings,
        slidesToShow: 4,
        slidesToScroll: 4,
        responsive: [
            ...responsiveSettings,
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4,
                },
            },
        ],
    };

    return (
        <Styles>
            <MDBContainer fluid className="px-0 overflow-hidden">
                <TitleImage image={image} title={title}/>
                <Breadcrumb active={title}/>
                <MDBRow>
                    <MDBCol size="12">
                        <div className="m-5">
                            <h2><b>A Solution for Every Need</b></h2>
                            <p>We provide logistical and claims management services for insurers, brokers and fleet
                                operators throughout the UK. Fully supported by a dedicated team of skilled insurance
                                claim negotiators, we implement a seamless extension to a client's business delivering
                                significant benefits including:</p>
                            <ul>
                                <li>Flexible service elements tailored to individual business needs</li>
                                <li>Control of claims expenditure through our economies of scale</li>
                                <li>Consistent and accurate information distribution between participants</li>
                                <li>Support and advice available 24/7</li>
                                <li>Innovative use of technology</li>
                                <li>Capability and resources to cope with variable workloads</li>
                                <li>Long established relationships with repairers</li>
                            </ul>
                            <p>A comprehensive complaint management and escalation strategy is built into our processes
                                based on FCA requirements and guidelines. This ensures any concerns raised are
                                responded to, actively managed and escalated as required. We embrace the FCA “Treating
                                Customers Fairly” principle and monitor this regularly.</p>
                            <br/>
                            <h5>Select a service below to find out more:</h5>
                        </div>
                    </MDBCol>
                </MDBRow>
                <MDBRow>
                    <MDBCol size="12">
                        <MDBContainer fluid>
                            <div className="services-slick-title">
                                <MDBRow className="align-items-center">
                                    <MDBCol size="8">
                                        <h3>Motor Claims</h3>
                                    </MDBCol>
                                    <MDBCol size="4" className="d-flex justify-content-end">
                                        <a href="/services/motor-claims">Find out more ></a>
                                    </MDBCol>
                                </MDBRow>
                            </div>
                            <div className="ms-5 me-5 mb-5">
                                <Slider {...largeSettings} className="row-cols-12">
                                    {motorServices.map((service, index) => {
                                        return (
                                            <MDBCol key={index} className="px-2 py-2">
                                                <MDBCard className="h-100">
                                                    <MDBCardImage src={service.image} alt={service.name} position="top"
                                                                  style={{
                                                                      height: 150,
                                                                      objectPosition: service.objectPosition,
                                                                  }}/>
                                                    <MDBCardHeader>{service.name}</MDBCardHeader>
                                                    <MDBCardBody>
                                                        <MDBCardText>
                                                            {service.information}
                                                        </MDBCardText>
                                                        <MDBCardLink href={service.link}>Find out more ></MDBCardLink>
                                                    </MDBCardBody>
                                                </MDBCard>
                                            </MDBCol>
                                        )
                                    })}
                                </Slider>
                            </div>
                        </MDBContainer>
                    </MDBCol>
                </MDBRow>
                <MDBRow>
                    <MDBCol size="12">
                        <MDBContainer fluid>
                            <div className="services-slick-title">
                                <MDBRow className="align-items-center">
                                    <MDBCol size="8">
                                        <h3>Technology</h3>
                                    </MDBCol>
                                    <MDBCol size="4" className="d-flex justify-content-end">
                                        <a href="/services/technology">Find out more ></a>
                                    </MDBCol>
                                </MDBRow>
                            </div>
                            <div className="ms-5 me-5 mb-5">
                                <Slider {...smallSettings} className="row-cols-12">
                                    {technologyServices.map((service, index) => {
                                        return (
                                            <MDBCol key={index} className="px-2 py-2">
                                                <MDBCard className="h-100">
                                                    <MDBCardImage src={service.image} alt={service.name} position="top"
                                                                  style={{
                                                                      height: 150,
                                                                      objectPosition: service.objectPosition,
                                                                  }}/>
                                                    <MDBCardHeader>{service.name}</MDBCardHeader>
                                                    <MDBCardBody>
                                                        <MDBCardText>
                                                            {service.information}
                                                        </MDBCardText>
                                                        <MDBCardLink href={service.link}>Find out more ></MDBCardLink>
                                                    </MDBCardBody>
                                                </MDBCard>
                                            </MDBCol>
                                        )
                                    })}
                                </Slider>
                            </div>
                        </MDBContainer>
                    </MDBCol>
                </MDBRow>
                <MDBRow>
                    <MDBCol size="12">
                        <MDBContainer fluid>
                            <div className="services-slick-title">
                                <MDBRow className="align-items-center">
                                    <MDBCol size="8">
                                        <h3>Audit</h3>
                                    </MDBCol>
                                    <MDBCol size="4" className="d-flex justify-content-end">
                                        <a href="/services/audit">Find out more ></a>
                                    </MDBCol>
                                </MDBRow>
                            </div>
                            <div className="ms-5 me-5 mb-5">
                                <Slider {...smallSettings} className="row-cols-12">
                                    {auditServices.map((service, index) => {
                                        return (
                                            <MDBCol key={index} className="px-2 py-2">
                                                <MDBCard className="h-100">
                                                    <MDBCardImage src={service.image} alt={service.name} position="top"
                                                                  style={{
                                                                      height: 150,
                                                                      objectPosition: service.objectPosition,
                                                                  }}/>
                                                    <MDBCardHeader>{service.name}</MDBCardHeader>
                                                    <MDBCardBody>
                                                        <MDBCardText>
                                                            {service.information}
                                                        </MDBCardText>
                                                        <MDBCardLink href={service.link}>Find out more ></MDBCardLink>
                                                    </MDBCardBody>
                                                </MDBCard>
                                            </MDBCol>
                                        )
                                    })}
                                </Slider>
                            </div>
                        </MDBContainer>
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
        </Styles>
    )
}
