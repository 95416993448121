import fnolImage from '../images/fnol.jpg';
import repairManagementImage from '../images/repair-management.jpg';
import engineeringImage from '../images/engineering.jpg';
import claimsHandlingImage from '../images/claims-handling.jpg';
import thirdPartyInterventionImage from '../images/third-party-intervention.jpg';

const motorClaimsServices = [
    {
        name: 'First Notification of Loss',
        image: fnolImage,
        information: '24/7 customer branded contact centre using cutting edge relationship building technology.',
        link: '/services/motor-claims/first-notification-of-loss',
        objectPosition: '0 90%',
    },
    {
        name: 'Repair Management',
        image: repairManagementImage,
        information: 'Nationwide network of quality approved repairers and vehicle recovery specialists.',
        link: '/services/motor-claims/repair-management',
        objectPosition: '0 50%',
    },
    {
        name: 'Engineering',
        image: engineeringImage,
        information: 'ATA accredited in-house desktop engineers and nationwide physical network.',
        link: '/services/motor-claims/engineering',
        objectPosition: '0 50%',
    },
    {
        name: 'Claims Handling',
        image: claimsHandlingImage,
        information: 'Dedicated service including recovery, theft, injury, total loss and third party settlement.',
        link: '/services/motor-claims/claims-handling',
        objectPosition: '0 50%',
    },
    {
        name: 'Third Party Intervention',
        image: thirdPartyInterventionImage,
        information: 'Specialising in client loss mitigation, fraud detection and third party monitoring.',
        link: '/services/motor-claims/third-party-intervention',
        objectPosition: '0 90%',
    },
];

export default motorClaimsServices;
