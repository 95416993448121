import React from 'react';
import styled from 'styled-components';
import {
    MDBContainer, MDBRow, MDBCol, MDBCard, MDBCardHeader, MDBCardBody, MDBCardText, MDBCardLink,
    MDBCardImage,
} from 'mdb-react-ui-kit';
import Breadcrumb from '../../components/Breadcrumb';
import TitleImage from '../../components/TitleImage';
import image from '../../assets/images/motor-claims.jpg';
import services from '../../assets/data/motorClaimsServices';

const Styles = styled.div`
`;

export default function MotorClaims() {
    const title = 'Motor Claims';
    const items = [{name: 'Services', link: '/services'}];

    return (
        <Styles>
            <MDBContainer fluid className="px-0 overflow-hidden">
                <TitleImage image={image} title={title} backgroundPositionY="65%"/>
                <Breadcrumb items={items} active={title}/>
                <MDBRow>
                    <MDBCol size="12">
                        <div className="m-5">
                            <h3><b>Acclaimed End-to-end Experience</b></h3>
                            <p>AAC is a leading provider of tailored cradle-to-grave motor claims handling, specialist
                                vehicle services and third-party intervention, working closely with insurers, MGAs,
                                brokers and fleet managers. We pride ourselves on the strong working partnerships we
                                form with our clients and have built a reputation for forward thinking, adaptive and
                                reliable solutions.</p>
                            <p> Our complete motor claims package provides a one stop shop, mitigating leakage and
                                offering a seamless experience to you and your customers.</p>
                            <br/>
                            <h5>Select a service below to find out more:</h5>
                        </div>
                    </MDBCol>
                </MDBRow>
                <MDBRow>
                    <MDBCol size="12">
                        <div className="ms-5 me-5 mb-5">
                            <MDBRow className="row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4">
                                {services.map((service, index) => {
                                    return (
                                        <MDBCol key={index} className="my-2">
                                            <MDBCard className="h-100">
                                                <MDBCardImage src={service.image} alt={service.name} position="top"
                                                              style={{
                                                                  height: 150,
                                                                  objectPosition: service.objectPosition,
                                                              }}/>
                                                <MDBCardHeader>{service.name}</MDBCardHeader>
                                                <MDBCardBody>
                                                    <MDBCardText>
                                                        {service.information}
                                                    </MDBCardText>
                                                    <MDBCardLink href={service.link}>Find out more ></MDBCardLink>
                                                </MDBCardBody>
                                            </MDBCard>
                                        </MDBCol>
                                    )
                                })}
                            </MDBRow>
                        </div>
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
        </Styles>
    )
}
