import React from 'react';
import styled from 'styled-components';
import {MDBContainer, MDBRow, MDBCol} from 'mdb-react-ui-kit';
import Breadcrumb from '../../../components/Breadcrumb';
import ContactBar from '../../../components/ContactBar';
import TitleImage from '../../../components/TitleImage';
import image from '../../../assets/images/engineering.jpg';
import imageLong from '../../../assets/images/engineering-long.jpg';
import desktopAssessmentIcon from '../../../assets/icons/desktop-assessment.png';
import physicalInspectionIcon from '../../../assets/icons/physical-inspection.png';
import valuationIcon from '../../../assets/icons/valuation.png';
import totalLossIcon from '../../../assets/icons/total-loss.png';
import industryApplicationsIcon from '../../../assets/icons/industry-applications.png';

const Styles = styled.div`
`;

export default function Engineering() {
    const title = 'Engineering';
    const items = [{name: 'Services', link: '/services'},
        {name: 'Motor Claims', link: '/services/motor-claims'}];

    return (
        <Styles>
            <MDBContainer fluid className="px-0 overflow-hidden">
                <TitleImage image={image} title={title} backgroundPositionY="45%"/>
                <Breadcrumb items={items} active={title}/>
                <MDBRow>
                    <MDBCol size="12">
                        <div className="m-5">
                            <h3><b>Controlling Your Costs</b></h3>
                            <p>We recognise the importance of swift and precise vehicle assessments to provide both a
                                smooth customer service and mitigate losses. With over 30 years experience in different
                                areas of the vehicle repair industry, our panel of engineers are equipped to deal with
                                any motor industry engineering including high-performance cars, HGVs and motorcycles. We
                                streamline the claim process to identify vehicles deemed beyond economical repair and
                                deliver a quick turnaround on payment. Our team maintain recognised qualifications,
                                including AQP for salvage categorisation and ATA accreditation, and are also members of
                                the Institute of Motoring Industries (IMI).</p>
                        </div>
                    </MDBCol>
                </MDBRow>
                <MDBRow className="grey-row">
                    <MDBCol size="3" className="d-md-flex d-none">
                        <img src={imageLong} alt="engineering" className="align-top vertical-image"/>
                    </MDBCol>
                    <MDBCol size="1" className="my-3 d-md-flex d-none justify-content-center vertical-title">
                        <div className="text-center">Services</div>
                    </MDBCol>
                    <MDBCol sm="12" md="8" id="service-icons" className="my-3">
                        <MDBRow>
                            <MDBCol size="3" md="2" className="d-flex align-items-center justify-content-center pe-0">
                                <img src={desktopAssessmentIcon} alt="desktop-assessment"/>
                            </MDBCol>
                            <MDBCol size="9" md="10">
                                <div className="m-4">
                                    <h4>Desktop Assessment</h4>
                                    <p>Desktop inspections are undertaken for all network repairers and the majority of
                                        non-network repairers to ensure efficient cost control, early authorisation and
                                        repair time management. Every engineer is supported by industry-trusted
                                        platforms to ensure appropriate repair methodologies are applied to every
                                        job.</p>
                                </div>
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol size="3" md="2" className="d-flex align-items-center justify-content-center pe-0">
                                <img src={physicalInspectionIcon} alt="physical-inspection"/>
                            </MDBCol>
                            <MDBCol size="9" md="10">
                                <div className="m-4">
                                    <h4>Physical Inspection</h4>
                                    <p>Nationwide, independent engineers complement our desktop team to provide physical
                                        inspections where required. These expert assessors bring a wealth of specialist
                                        knowledge on motorcycle, commercial and agricultural cases. Our in-house
                                        engineer support team provide an interface to our external panel allowing them
                                        to operate as a seamless extension of our engineering solutions.</p>
                                </div>
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol size="3" md="2" className="d-flex align-items-center justify-content-center pe-0">
                                <img src={valuationIcon} alt="valuation"/>
                            </MDBCol>
                            <MDBCol size="9" md="10">
                                <div className="m-4">
                                    <h4>Valuation</h4>
                                    <p>Our engineers, using industry recognised platforms and intelligent consideration
                                        of the vehicle market, provide accurate and fair valuations. In the event of
                                        stolen or total loss vehicles, through investigation and supplied documentation
                                        we pursue timely evaluations to minimise downtime for your clients.</p>
                                </div>
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol size="3" md="2" className="d-flex align-items-center justify-content-center pe-0">
                                <img src={totalLossIcon} alt="total-loss"/>
                            </MDBCol>
                            <MDBCol size="9" md="10">
                                <div className="m-4">
                                    <h4>Total Loss</h4>
                                    <p>We provide a full total loss service including liaising with policy holders,
                                        agreeing settlement, and instructing salvage agents. With the assistance of our
                                        claims management platforms our repair agents identify potential total loss
                                        cases at the earliest opportunity expediting the conclusion of these claims.</p>
                                </div>
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol size="3" md="2" className="d-flex align-items-center justify-content-center pe-0">
                                <img src={industryApplicationsIcon} alt="industry-applications"/>
                            </MDBCol>
                            <MDBCol size="9" md="10">
                                <div className="m-4">
                                    <h4>Industry Applications</h4>
                                    <p>To ensure we provide fair and accurate assessments, we use the latest standard in
                                        industry engineering software, including Audatex and Glass Evaluator to
                                        supplement national vehicle history monitoring tools.</p>
                                </div>
                            </MDBCol>
                        </MDBRow>
                    </MDBCol>
                </MDBRow>
                <ContactBar/>
            </MDBContainer>
        </Styles>
    )
}
