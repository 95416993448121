import React from 'react';
import styled from 'styled-components';
import {
    MDBContainer, MDBRow, MDBCol, MDBIcon, MDBCard, MDBCardHeader, MDBCardBody, MDBCardText,
    MDBCardLink, MDBCardImage,
} from 'mdb-react-ui-kit';
import ContactBar from '../components/ContactBar';
import mini from '../assets/images/mini.jpg';
import services from '../assets/data/services';

const Styles = styled.div`
    .page-title {
        color: white;
        background: linear-gradient(58deg, #085DCF 95%, transparent 0%);
    
        h1 {
            padding: 0.3rem 0;
        }
    }
    
     @media only screen and (min-width: 500px) and (max-width: 895px) {
        .page-title {
            background: linear-gradient(58deg, #085DCF 89%, transparent 0%);
        }
    }
    
    @media only screen and (max-width: 500px) {
        .page-title {
            background: linear-gradient(58deg, #085DCF 84%, transparent 0%);
        }
    }
    
    .client-images {
        img {
            filter: grayscale(100%);
            opacity: 0.6;
        }
    }

    img.shadow-image {
        width: 80%;
        height: auto;
        filter: brightness(0.9);
        display: inline-block;
        box-shadow: -24px -24px 0 var(--mdb-gray-400);
    }
    
    i {
        padding-right: 5px;
    }
`;

export const Home = () => {
    return (
        <Styles>
            <MDBContainer fluid>
                <div className="d-flex justify-content-md-start align-items-end h-100">
                    <MDBRow className="page-title">
                        <MDBCol size="12">
                            <div className="px-5">
                                <h1>Accident Management You Can Rely On</h1>
                            </div>
                        </MDBCol>
                    </MDBRow>
                </div>
                <MDBRow>
                    <MDBCol size="12">
                        <div className="m-5">
                            <p>For over twenty years, Auto Accident Claims have been helping companies enhance their
                                profitability through smarter accident management.</p>
                            <p>We provide a professional and bespoke service for a wide range of schemes including
                                private car, motorcycle, bus/coach and commercial vehicle fleets ranging from micro
                                enterprises to large corporate risks. We believe in full communication, trust and
                                transparency, and aim to establish a close, long term relationship which compliments
                                your business model and enhances your reputation. It is our innovative approach to fully
                                understand a client's requirements that enables us to become experts in the provision of
                                accident and claims management services. Working closely with our clients we create
                                tailored solutions which control costs, fine tune operations, and manage risks. We
                                demonstrate on a daily basis our expertise, professionalism, integrity and passion for
                                serving the industry and ensuring our clients receive the quality of service others can
                                only aspire to. Immensely proud of our people, we have one of the most dedicated and
                                driven professional team of experts who make up the character and energy that sets us
                                apart from other companies.</p>
                            <p>Whether you are an insurer looking for a complete handling solution or an independent
                                fleet operator looking for total management, entering a partnership with us will make
                                all the difference.</p>
                        </div>
                    </MDBCol>
                </MDBRow>
                <MDBRow className="grey-container">
                    <MDBCol size="12">
                        <div className="m-5">
                            <h2>Our Services</h2>
                            <MDBRow className="row-cols-1 row-cols-sm-2 row-cols-md-3">
                                {services.map((service, index) => {
                                    return (
                                        <MDBCol key={index} className="my-2">
                                            <MDBCard className="h-100">
                                                <MDBCardImage src={service.image} alt={service.name} position="top"
                                                              style={{height: 200}}/>
                                                <MDBCardHeader>{service.name}</MDBCardHeader>
                                                <MDBCardBody>
                                                    <MDBCardText>
                                                        {service.information}
                                                    </MDBCardText>
                                                    <MDBCardLink href={service.link}>Find out more ></MDBCardLink>
                                                </MDBCardBody>
                                            </MDBCard>
                                        </MDBCol>
                                    )
                                })}
                            </MDBRow>
                        </div>
                    </MDBCol>
                </MDBRow>
                <MDBRow className="d-flex align-items-center blue-container">
                    <MDBCol sm="12" md="6" className="text-center">
                        <img src={mini} alt="mini" className="m-4 shadow-image"/>
                    </MDBCol>
                    <MDBCol sm="12" md="6">
                        <div className="m-5 text-center">
                            <h3>Why Choose AAC?</h3>
                            <p><MDBIcon fas icon="check"/> Customer centric focus</p>
                            <p><MDBIcon fas icon="check"/> Solutions tailored to your brand</p>
                            <p><MDBIcon fas icon="check"/> Total end to end claims service</p>
                            <p><MDBIcon fas icon="check"/> Proven track record within the industry</p>
                            <p><MDBIcon fas icon="check"/> FCA regulated</p>
                        </div>
                    </MDBCol>
                </MDBRow>
                <MDBRow className="d-flex align-items-center grey-container">
                    <MDBCol size="12" className="text-center">
                        <div className="m-5">
                            <h3 className="quote"><i>“Your outstanding commitment to customer service gives us the
                                confidence to know our reputation is in good hands”</i></h3>
                        </div>
                    </MDBCol>
                </MDBRow>
                <ContactBar/>
            </MDBContainer>
        </Styles>
    );
}
