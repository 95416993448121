import React from 'react';
import styled from 'styled-components';
import {MDBContainer, MDBRow, MDBCol} from 'mdb-react-ui-kit';
import Breadcrumb from '../../../components/Breadcrumb';
import ContactBar from '../../../components/ContactBar';
import TitleImage from '../../../components/TitleImage';
import image from '../../../assets/images/claims-management-system.jpg';
import imageLong from '../../../assets/images/claims-management-system-long.jpg';
import userInterfaceIcon from '../../../assets/icons/user-interface.png';
import accessIcon from '../../../assets/icons/access.png';
import automationIcon from '../../../assets/icons/automation.png';
import integrationIcon from '../../../assets/icons/integration.png';

const Styles = styled.div`
`;

export default function ClaimsManagementSystem() {
    const title = 'Claims Management System';
    const items = [{name: 'Services', link: '/services'},
        {name: 'Technology', link: '/services/technology'}];

    return (
        <Styles>
            <MDBContainer fluid className="px-0 overflow-hidden">
                <TitleImage image={image} title={title} backgroundPositionY="50%"/>
                <Breadcrumb items={items} active={title}/>
                <MDBRow>
                    <MDBCol size="12">
                        <div className="m-5">
                            <h3><b>Revolutionise Your Claims</b></h3>
                            <p>To better handle the constantly changing world of insurance, we develop our own in-house
                                claims handling application, Tonic. With Tonic, we can facilitate every aspect of the
                                claims process, including FNOL, repair management, engineering and claim settlement.
                                From initial submission to final settlement, our platform ensures seamless
                                communication, reduces paperwork, and minimizes errors. With real-time tracking,
                                analytics, and compliance features, your team can focus on delivering exceptional
                                customer service while maximizing efficiency.</p>
                            <p>Whether you are a start-up MGA or well-established insurer, Tonic grows with you. As your
                                portfolio expands, our elastic infrastructure scales horizontally, handling increased
                                loads without breaking a sweat.</p>
                        </div>
                    </MDBCol>
                </MDBRow>
                <MDBRow className="grey-row">
                    <MDBCol size="3" className="d-md-flex d-none">
                        <img src={imageLong} alt="claims-management-system" className="align-top vertical-image"/>
                    </MDBCol>
                    <MDBCol size="1" className="my-3 d-md-flex d-none justify-content-center vertical-title">
                        <div className="text-center">Services</div>
                    </MDBCol>
                    <MDBCol sm="12" md="8" id="service-icons" className="my-3">
                        <MDBRow>
                            <MDBCol size="3" md="2" className="d-flex align-items-center justify-content-center pe-0">
                                <img src={userInterfaceIcon} alt="user-interface"/>
                            </MDBCol>
                            <MDBCol size="9" md="10">
                                <div className="m-4">
                                    <h4>User Interface</h4>
                                    <p>Built with the user in mind, our front-end design ensures an intuitive and
                                        responsive interface, allowing claims agents to effortlessly move through
                                        different stages of the process. Whether it is submitting a new claim, reviewing
                                        documentation, or communicating with stakeholders, the layout ensures a minimal
                                        learning curve.</p>
                                    <p>Uploading, organizing, and accessing claim-related documents is seamless.
                                        Drag-and-drop functionality, user friendly tagging, and secure storage ensure
                                        that all necessary files are readily available.</p>
                                </div>
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol size="3" md="2" className="d-flex align-items-center justify-content-center pe-0">
                                <img src={accessIcon} alt="access"/>
                            </MDBCol>
                            <MDBCol size="9" md="10">
                                <div className="m-4">
                                    <h4>Access</h4>
                                    <p>Availability is key to any software platform. With our cloud-based
                                        infrastructure, you are not tied to a physical location. Whether you are working
                                        from your office, home, or on the go, we have you covered all while still making
                                        sure your data is safe and secure.</p>
                                </div>
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol size="3" md="2" className="d-flex align-items-center justify-content-center pe-0">
                                <img src={automationIcon} alt="automation"/>
                            </MDBCol>
                            <MDBCol size="9" md="10">
                                <div className="m-4">
                                    <h4>Automation</h4>
                                    <p>Automation helps cut down on manual labour, paper resources, and compliance
                                        risks. It also enables scalability without proportional increases in costs.
                                        Diary tracking, automated condition alerting, and integrated dashboards allow us
                                        to react to incoming risks and mitigate their impact.</p>
                                </div>
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol size="3" md="2" className="d-flex align-items-center justify-content-center pe-0">
                                <img src={integrationIcon} alt="integration"/>
                            </MDBCol>
                            <MDBCol size="9" md="10">
                                <div className="m-4">
                                    <h4>Integration</h4>
                                    <p>Tonic readily adapts to its surroundings. Whether you are working with legacy
                                        systems, cloud-based applications, or cutting-edge APIs, we can provide
                                        effortless integration, ensuring a smooth transition without disrupting your
                                        existing workflows.</p>
                                </div>
                            </MDBCol>
                        </MDBRow>
                    </MDBCol>
                </MDBRow>
                <ContactBar/>
            </MDBContainer>
        </Styles>
    )
}
