import motorClaimsImage from '../images/motor-claims.jpg';
import technologyImage from '../images/technology.jpg';
import auditImage from '../images/audit.jpg';

const services = [{
    name: 'Motor Claims',
    image: motorClaimsImage,
    information: 'Tailored motor claims product providing a cradle-to-grave service to insurers, brokers and fleets.',
    link: '/services/motor-claims',
}, {
    name: 'Technology',
    image: technologyImage,
    information: 'Forward thinking technologies developed in-house to cater for all your requirements.',
    link: '/services/technology',
}, {
    name: 'Audit',
    image: auditImage,
    information: 'Full claim lifecycle analysis, driving excellence throughout your business to put you ahead of the competition.',
    link: '/services/audit',
}];

export default services;
