import React from 'react';
import styled from 'styled-components';
import {MDBContainer, MDBRow, MDBCol} from 'mdb-react-ui-kit';
import Breadcrumb from '../components/Breadcrumb';

const Styles = styled.div`
`;

export default function PrivacyPolicy() {
    const title = 'Privacy Policy';

    return (
        <Styles>
            <MDBContainer fluid>
                <Breadcrumb active={title}/>
                <MDBRow>
                    <MDBCol size="12">
                        <div className="m-5 info-only">
                            <h2>Terms & Conditions</h2>
                            <p>By browsing and using this website, you are agreeing to comply with and be bound by the
                                following terms and conditions of use, which together with our privacy policy govern
                                AAC's relationship with you in relation to this website.</p>
                            <p>In this agreement, “we”, “us”, “our” or “AAC” will refer to Auto Accident Claims Ltd
                                whose registered office is Suite D, The Business Centre, Faringdon Avenue, Romford,
                                Essex RM3 8EN. Our company registration number is 4415039. The terms “you”, “your” will
                                refer to you.</p>
                            <p>The use of this website is subject to the following terms of use:</p>
                            <ul>
                                <li>We may revise these terms from time to time. If we do, those revised terms will
                                    supersede prior versions.
                                </li>
                                <li>The content of the pages of this website are for your general information and use
                                    only.
                                </li>
                                <li>Neither we nor any third parties provide any warranty or guarantee as to the
                                    accuracy, timeliness, performance, completeness or suitability of the information
                                    and materials found or offered on this website for any particular purpose. You
                                    acknowledge such information and materials may contain inaccuracies or errors and we
                                    expressly exclude liability for any such errors or inaccuracies to the fullest
                                    extent permitted by law.
                                </li>
                                <li>Your use of any information or materials on this website is entirely at your own
                                    risk, for which we shall not be liable. It shall be your own responsibility to
                                    ensure any products, services or information available through this website meet
                                    your specific requirements.
                                </li>
                                <li>This website contains material which is owned by or licensed to us. The material
                                    includes, but is not limited to, the design, layout, look, appearance and graphics.
                                    Reproduction is prohibited other than in accordance with the copyright notice, which
                                    forms part of these terms of service.
                                </li>
                                <li>All trademarks reproduced on this website which are not the property of, or licensed
                                    to the operator, are acknowledged on this website.
                                </li>
                                <li>Unauthorised use of this website is a criminal offence under the Computer Misuse Act
                                    1990 and may give rise to a claim for damages.
                                </li>
                                <li>From time to time, this website may include links to other websites. These links are
                                    provided for your convenience to provide further information. They do not signify us
                                    endorsing this website(s). We have no responsibility for the content of the linked
                                    website(s).
                                </li>
                                <li>Your use of this website and any dispute arising out of such use of this website is
                                    subject to the laws of England, Northern Ireland, Scotland and Wales.
                                </li>
                            </ul>
                            <h2 style={{paddingTop: 20}}>Modern Slavery</h2>
                            <h4>What is Modern Slavery?</h4>
                            <p>Modern slavery is a term used to encompass:</p>
                            <ul>
                                <li><b>Slavery:</b> where ownership is exercised over an individual</li>
                                <li><b>Servitude:</b> involves the obligation to provide service imposed by coercion
                                </li>
                                <li><b>Forced and compulsory labour:</b> all work or service, not voluntarily performed,
                                    which is obtained from an individual under the threat of force or penalty
                                </li>
                                <li><b>Human trafficking:</b> is where a person arranges or facilitates the travel of
                                    another person with a view to that person being exploited
                                </li>
                            </ul>
                            <h4>Our Commitment</h4>
                            <p>We expect everyone working with us or on our behalf to support and uphold the following
                                measures to safeguard against modern slavery. We have a zero-tolerance approach to
                                modern slavery in our organisation and our supply chains. The prevention, detection and
                                reporting of modern slavery in any part of our organisation or supply chain is the
                                responsibility of all those working for us or on our behalf. Workers must not engage in,
                                facilitate or fail to report any activity that might lead to, or suggest, a breach of
                                this policy.</p>
                            <p>We are committed to engaging with our stakeholders and suppliers to address the risk of
                                modern slavery in our operations and supply chain. Consistent with our risk based
                                approach we may require:</p>
                            <ul>
                                <li>Employment and recruitment agencies and other third parties supplying workers to our
                                    organisation to confirm their compliance with our Code of Conduct.
                                </li>
                                <li>Suppliers engaging workers through a third party to obtain that third parties’
                                    agreement to adhere to the Code.
                                </li>
                                <li>As part of our ongoing risk assessment and due diligence processes we will consider
                                    whether circumstances warrant us carrying out audits of suppliers for their
                                    compliance with our Code of Conduct.
                                </li>
                                <li>If we find that other individuals or organisations working on our behalf have
                                    breached this policy we will ensure that we take appropriate action. This may range
                                    from considering the possibility of breaches being remediated and whether that might
                                    represent the best outcome for those individuals impacted by the breach to
                                    terminating such relationship.
                                </li>
                            </ul>
                        </div>
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
        </Styles>
    )
}
