import React from 'react';
import styled from 'styled-components';
import {MDBContainer, MDBRow, MDBCol} from 'mdb-react-ui-kit';
import Breadcrumb from '../../../components/Breadcrumb';
import ContactBar from '../../../components/ContactBar';
import TitleImage from '../../../components/TitleImage';
import image from '../../../assets/images/quality-and-compliance.jpg';
import imageLong from '../../../assets/images/quality-and-compliance-long.jpg';
import controlIcon from '../../../assets/icons/control.png';
import adherenceIcon from '../../../assets/icons/adherence.png';
import regulationIcon from '../../../assets/icons/regulation.png';

const Styles = styled.div`
`;

export default function QualityAndCompliance() {
    const title = 'Quality and Compliance';
    const items = [{name: 'Services', link: '/services'},
        {name: 'Audit', link: '/services/audit'}];

    return (
        <Styles>
            <MDBContainer fluid className="px-0 overflow-hidden">
                <TitleImage image={image} title={title} backgroundPositionY="50%"/>
                <Breadcrumb items={items} active={title}/>
                <MDBRow>
                    <MDBCol size="12">
                        <div className="m-5">
                            <h3><b>Excellence Through Diligence</b></h3>
                            <p>Quality and compliance audits are essential for businesses to ensure they are meeting the
                                standards of their customers, employees, and external regulators.</p>
                            <p>In addition, better quality and compliance management practices can lead to sustainable
                                competitive advantages for a business. By deploying emerging best practices in quality
                                and compliance management, businesses can unlock levels of competitiveness and brand
                                equity that are hard to imitate.</p>
                        </div>
                    </MDBCol>
                </MDBRow>
                <MDBRow className="grey-row">
                    <MDBCol size="3" className="d-md-flex d-none">
                        <img src={imageLong} alt="operations" className="align-top vertical-image"/>
                    </MDBCol>
                    <MDBCol size="1" className="my-3 d-md-flex d-none justify-content-center vertical-title">
                        <div className="text-center">Services</div>
                    </MDBCol>
                    <MDBCol sm="12" md="8" id="service-icons" className="my-3">
                        <MDBRow>
                            <MDBCol size="3" md="2" className="d-flex align-items-center justify-content-center pe-0">
                                <img src={controlIcon} alt="control"/>
                            </MDBCol>
                            <MDBCol size="9" md="10">
                                <div className="m-4">
                                    <h4>Control</h4>
                                    <p>In accordance with TCF (Treating Customers Fairly) it is key to offer a
                                        consistent level of claims quality to all your policyholders. As part of our
                                        service, we help eliminate inconsistencies and remove ambiguity without
                                        hindering your teams’ ability to provide a bespoke and unique service.</p>
                                </div>
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol size="3" md="2" className="d-flex align-items-center justify-content-center pe-0">
                                <img src={adherenceIcon} alt="adherence"/>
                            </MDBCol>
                            <MDBCol size="9" md="10">
                                <div className="m-4">
                                    <h4>Adherence</h4>
                                    <p>Developing strong policies and procedures is only effective if you rigorously
                                        apply said principles throughout your organisation. We benchmark your
                                        departments to guarantee your compliance is consistent across every service
                                        offering from business continuity to information security.</p>
                                </div>
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol size="3" md="2" className="d-flex align-items-center justify-content-center pe-0">
                                <img src={regulationIcon} alt="regulation"/>
                            </MDBCol>
                            <MDBCol size="9" md="10">
                                <div className="m-4">
                                    <h4>Regulation</h4>
                                    <p>External compliance is ever changing to adapt to an evolving industry and keeping
                                        on top of the latest requirements can be time consuming for your staff. We can
                                        provide concise guidance to keep you ahead of regulation so you can focus on
                                        growing your business.</p>
                                </div>
                            </MDBCol>
                        </MDBRow>
                    </MDBCol>
                </MDBRow>
                <ContactBar/>
            </MDBContainer>
        </Styles>
    )
}
