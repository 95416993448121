import React from 'react';
import styled from 'styled-components';
import {MDBContainer, MDBRow, MDBCol} from 'mdb-react-ui-kit';
import Breadcrumb from '../../../components/Breadcrumb';
import ContactBar from '../../../components/ContactBar';
import TitleImage from '../../../components/TitleImage';
import image from '../../../assets/images/third-party-intervention.jpg';
import imageLong from '../../../assets/images/third-party-intervention-long.jpg';
import repairIcon from '../../../assets/icons/repair.png';
import hireIcon from '../../../assets/icons/hire.png';
import mitigationIcon from '../../../assets/icons/mitigation.png';
import monitoringIcon from '../../../assets/icons/monitoring.png';

const Styles = styled.div`
     @media only screen and (max-width: 895px) {
        .bg-image {
            background-position-y: 100% !important;
        }
    }
`;

export default function ThirdPartyIntervention() {
    const title = 'Third Party Intervention';
    const items = [{name: 'Services', link: '/services'},
        {name: 'Motor Claims', link: '/services/motor-claims'}];

    return (
        <Styles>
            <MDBContainer fluid className="px-0 overflow-hidden">
                <TitleImage image={image} title={title} backgroundPositionY="82%"/>
                <Breadcrumb items={items} active={title}/>
                <MDBRow>
                    <MDBCol size="12">
                        <div className="m-5">
                            <h3><b>Containing the Risks</b></h3>
                            <p>When a policy holder has been involved in a fault accident with a third party, we know
                                the importance of acting quickly to prevent burdening our clients with inflated costs.
                                Once we are notified of a potential capture, the third party is contacted within one
                                hour utilising all available methods of communication. Our aim is to reduce frictional
                                costs for our client whilst offering a high level of service to the third party. We have
                                an excellent record on TP intervention and are able to offer in-house end to end
                                services including replacement vehicles, repairs, total loss settlement, and personal
                                injury rehabilitation. We record a 70% success rate on captures when claims are reported
                                within 24 hours.</p>
                        </div>
                    </MDBCol>
                </MDBRow>
                <MDBRow className="grey-row">
                    <MDBCol size="3" className="d-md-flex d-none">
                        <img src={imageLong} alt="third-party-intervention" className="align-top vertical-image"/>
                    </MDBCol>
                    <MDBCol size="1" className="my-3 d-md-flex d-none justify-content-center vertical-title">
                        <div className="text-center">Services</div>
                    </MDBCol>
                    <MDBCol sm="12" md="8" id="service-icons" className="my-3">
                        <MDBRow>
                            <MDBCol size="3" md="2" className="d-flex align-items-center justify-content-center pe-0">
                                <img src={repairIcon} alt="repair"/>
                            </MDBCol>
                            <MDBCol size="9" md="10">
                                <div className="m-4">
                                    <h4>Repair</h4>
                                    <p>We look to convert third parties into our approved repairer network to reduce
                                        costs and control the downtime on their vehicles. Our excellent coverage and
                                        solid working relationships that we hold with our suppliers provides us with a
                                        responsive and rapid turnaround, making our offering desirable to third parties
                                        seeking a speedy resolution.</p>
                                </div>
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol size="3" md="2" className="d-flex align-items-center justify-content-center pe-0">
                                <img src={hireIcon} alt="hire"/>
                            </MDBCol>
                            <MDBCol size="9" md="10">
                                <div className="m-4">
                                    <h4>Hire</h4>
                                    <p>We use a nationwide hire network, consisting of multiple providers with
                                        competitive rates on standard and specialist vehicles. This versatile offering
                                        affords us a wide range of vehicle options maximising our potential to attract
                                        third party claimants to our service. Collection and delivery are included in
                                        the package to further increase the likelihood of conversion.</p>
                                </div>
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol size="3" md="2" className="d-flex align-items-center justify-content-center pe-0">
                                <img src={mitigationIcon} alt="mitigation"/>
                            </MDBCol>
                            <MDBCol size="9" md="10">
                                <div className="m-4">
                                    <h4>Mitigation</h4>
                                    <p>Intercepting potential high-cost outlays to our client is key. Our service
                                        identifies risks such as potential injuries and non-approved credit hire
                                        enabling our agents to triage claims and initiate negotiation at the earliest
                                        opportunity.</p>
                                </div>
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol size="3" md="2" className="d-flex align-items-center justify-content-center pe-0">
                                <img src={monitoringIcon} alt="monitoring"/>
                            </MDBCol>
                            <MDBCol size="9" md="10">
                                <div className="m-4">
                                    <h4>Monitoring</h4>
                                    <p>All repairs and hires are monitored daily to control costs and prevent
                                        unnecessary exaggeration. We constantly review the notification process to
                                        maintain the highest possible capture rate. Department leads regularly
                                        collaborate to keep our service agents apprised of the latest trends and key
                                        risk identifiers.</p>
                                </div>
                            </MDBCol>
                        </MDBRow>
                    </MDBCol>
                </MDBRow>
                <ContactBar/>
            </MDBContainer>
        </Styles>
    )
}
