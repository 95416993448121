import React from 'react';
import styled from 'styled-components';
import {
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBCard,
    MDBCardImage,
    MDBCardHeader,
    MDBCardBody,
    MDBCardText, MDBCardLink,
} from 'mdb-react-ui-kit';
import Breadcrumb from '../../components/Breadcrumb';
import TitleImage from '../../components/TitleImage';
import services from '../../assets/data/technologyServices';
import image from '../../assets/images/technology.jpg';

const Styles = styled.div`
`;

export default function Technology() {
    const title = 'Technology';
    const items = [{name: 'Services', link: '/services'}];

    return (
        <Styles>
            <MDBContainer fluid className="px-0 overflow-hidden">
                <TitleImage image={image} title={title} backgroundPositionY="55%"/>
                <Breadcrumb items={items} active={title}/>
                <MDBRow>
                    <MDBCol size="12">
                        <div className="m-5">
                            <h3><b>Looking to the Future</b></h3>
                            <p>We know that our clients bring a wealth of knowledge and experience to the table, which
                                is why we always work together to design mutually beneficial solutions. Our main goal is
                                to simplify procedures, enhance productivity and deliver excellence. AAC offer a suite
                                of services to support both you and your customers throughout the duration of their
                                claim. We continue to invest in emerging technologies, building cloud based, scalable
                                solutions for insurance businesses of all sizes. We also integrate with your existing
                                technology suppliers for the ultimate customer experience.</p>
                            <p>Our technology is your technology.</p>
                            <br/>
                            <h5>Select a service below to find out more:</h5>
                        </div>
                    </MDBCol>
                </MDBRow>
                <MDBRow>
                    <MDBCol size="12">
                        <div className="ms-5 me-5 mb-5">
                            <MDBRow className="row-cols-1 row-cols-sm-2 row-cols-md-3 ">
                                {services.map((service, index) => {
                                    return (
                                        <MDBCol key={index} className="my-2">
                                            <MDBCard className="h-100">
                                                <MDBCardImage src={service.image} alt={service.name} position="top"
                                                              style={{
                                                                  height: 150,
                                                                  objectPosition: service.objectPosition,
                                                              }}/>
                                                <MDBCardHeader>{service.name}</MDBCardHeader>
                                                <MDBCardBody>
                                                    <MDBCardText>
                                                        {service.information}
                                                    </MDBCardText>
                                                    <MDBCardLink href={service.link}>Find out more ></MDBCardLink>
                                                </MDBCardBody>
                                            </MDBCard>
                                        </MDBCol>
                                    )
                                })}
                            </MDBRow>
                        </div>
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
        </Styles>
    )
}
