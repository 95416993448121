import React, {useState} from 'react';
import styled from 'styled-components';
import {useLocation} from 'react-router-dom';
import {MDBCarousel, MDBCarouselInner, MDBCarouselItem, MDBCarouselElement, MDBCarouselCaption} from 'mdb-react-ui-kit';
import image1 from '../assets/images/road.jpg';
import image2 from '../assets/images/chameleon.jpg';
import image3 from '../assets/images/handshake.jpg';

const Styles = styled.div`
    .carousel {
        border-bottom: 3px solid var(--aac-blue);
    
        .carousel-inner {
            height: 325px;
            img {
                height: 325px;
                filter: brightness(0.7);
                object-fit: cover;
            }
            
            .carousel-caption {
                display: block !important;
            }
        }
    } 
`;

function ImageCarousel() {
    const location = useLocation();
    const path = location.pathname;
    const [display] = useState(
        path === "/" ? true : false,
    );

    return (
        <>
            {display && (
                <Styles>
                    <MDBCarousel showIndicators fade interval={4000}>
                        <MDBCarouselInner>
                            <MDBCarouselItem className="active">
                                <MDBCarouselElement src={image1} alt="road" style={{objectPosition: "0 50%"}}/>
                                <MDBCarouselCaption>
                                    <h5>Assess</h5>
                                    <p>Your needs are unique and so are our solutions</p>
                                </MDBCarouselCaption>
                            </MDBCarouselItem>
                            <MDBCarouselItem>
                                <MDBCarouselElement src={image2} alt="chameleon" style={{objectPosition: "0 36%"}}/>
                                <MDBCarouselCaption>
                                    <h5>Adapt</h5>
                                    <p>We evolve with you and your product to stay ahead of the game</p>
                                </MDBCarouselCaption>
                            </MDBCarouselItem>
                            <MDBCarouselItem>
                                <MDBCarouselElement src={image3} alt="handshake" style={{objectPosition: "0 70%"}}/>
                                <MDBCarouselCaption>
                                    <h5>Accomplish</h5>
                                    <p>But it doesn't stop there - on to the next challenge!</p>
                                </MDBCarouselCaption>
                            </MDBCarouselItem>
                        </MDBCarouselInner>
                    </MDBCarousel>
                </Styles>
            )}
        </>
    );
}

export default ImageCarousel;
