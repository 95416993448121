import claimsManagementSystemImage from '../images/claims-management-system.jpg';
import managementInformationImage from '../images/management-information.jpg';
import communicationImage from '../images/communication.jpg';

const technologyServices = [
    {
        name: 'Claims Management System',
        image: claimsManagementSystemImage,
        information: 'Comprehensive suite of globally accessible applications to automate, inform and steer your portfolio to new levels of excellence.',
        link: '/services/technology/claims-management-system',
        objectPosition: '0 90%',
    },
    {
        name: 'Management Information',
        image: managementInformationImage,
        information: 'Thorough analysis of every facet of your business from transactional breakdown, to in depth performance analysis and benchmarking.',
        link: '/services/technology/management-information',
        objectPosition: '0 65%',
    },
    {
        name: 'Communication',
        image: communicationImage,
        information: 'Modern adaptive technologies from web platforms to smart telephony, to reach and inform your clients at every step of their journey.',
        link: '/services/technology/communication',
        objectPosition: '0 75%',
    },
];

export default technologyServices;
