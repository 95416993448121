import React from 'react';
import styled from 'styled-components';
import {MDBFooter, MDBContainer, MDBRow, MDBCol} from 'mdb-react-ui-kit';

const Styles = styled.div`
    .footer-small {
        font-size: 0.75em;
    }

    .row {
        p {
            color: var(--mdb-gray);
        }

        div {
            &:not(:first-child) {
                @media only screen and (min-width: 768px) {
                    border-left: 1px solid var(--mdb-gray);
                }
            }

            p {
                margin-bottom: 0;
            }

            a, i {
                color: var(--mdb-gray);

                &:hover {
                    color: var(--mdb-white);
                    text-decoration: none;
                }
            }
        }
    }
`;

export const Footer = () => {
    return (
        <Styles>
            <MDBFooter bgColor="dark">
                <MDBContainer className="p-3">
                    <MDBRow className="text-center footer-small">
                        <p>Auto Accident Claims Ltd. is registered in England, Suite D, The Business Centre, Faringdon
                            Avenue, Romford, Essex RM3 8EN</p>
                        <p><b>Company No.</b> 4415039 | <b>VAT Registration No.</b> 798 6704 58</p>
                    </MDBRow>
                    <MDBRow className="footer-small pt-3">
                        <MDBCol lg="3" md="3" className="mb-md-0 text-center">
                            <p>&copy; {new Date().getFullYear()} Auto Accident Claims Ltd.</p>
                        </MDBCol>
                        <MDBCol lg="3" md="3" className="mb-md-0 text-center">
                            <a href="/accessibility">Accessibility</a>
                        </MDBCol>
                        <MDBCol lg="2" md="2" className="mb-md-0 text-center">
                            <a href="/privacypolicy">Privacy Policy</a>
                        </MDBCol>
                        <MDBCol lg="2" md="2" className="mb-md-0 text-center">
                            <a href="/complaints">Complaints</a>
                        </MDBCol>
                        <MDBCol lg="2" md="2" className="mb-md-0 text-center">
                            <a href="/terms">Terms</a>
                        </MDBCol>
                        {/*<MDBCol lg="1" md="1" className="mb-md-0 text-center">*/}
                        {/*    <a href="#!"><i className="fab fa-linkedin-in"></i></a>*/}
                        {/*</MDBCol>*/}
                    </MDBRow>
                </MDBContainer>
            </MDBFooter>
        </Styles>
    );
}
