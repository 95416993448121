import React from 'react';
import styled from 'styled-components';
import {MDBContainer, MDBRow, MDBCol} from 'mdb-react-ui-kit';
import Breadcrumb from '../../../components/Breadcrumb';
import ContactBar from '../../../components/ContactBar';
import TitleImage from '../../../components/TitleImage';
import image from '../../../assets/images/repair-management.jpg';
import imageLong from '../../../assets/images/repair-management-long.jpg';
import networkIcon from '../../../assets/icons/network.png';
import recoveryIcon from '../../../assets/icons/recovery.png';
import qualityIcon from '../../../assets/icons/quality.png';
import windscreenIcon from '../../../assets/icons/windscreen.png';

const Styles = styled.div`
`;

export default function RepairManagement() {
    const title = 'Repair Management';
    const items = [{name: 'Services', link: '/services'},
        {name: 'Motor Claims', link: '/services/motor-claims'}];

    return (
        <Styles>
            <MDBContainer fluid className="px-0 overflow-hidden">
                <TitleImage image={image} title={title} backgroundPositionY="65%"/>
                <Breadcrumb items={items} active={title}/>
                <MDBRow>
                    <MDBCol size="12">
                        <div className="m-5">
                            <h3><b>Striving For Excellence</b></h3>
                            <p>Your customers depend on their vehicles so it is vital that we keep them moving. Our
                                dedicated repair management team ensure vehicle downtime is kept to an absolute minimum
                                whilst mitigating repair and vehicle replacement costs. All of our garages provide
                                courtesy vehicles where applicable to facilitate this. Our competent network of
                                repairers deliver the highest quality of service with all work guaranteed, providing
                                peace of mind and customer satisfaction. No matter the extent of the damage, every job
                                is completed to the same grade A standard.</p>
                            <p>Satisfied policy holders can lead to increased retention and the purchase of additional
                                policies. A successful service is the best advert you can give to your client.</p>
                        </div>
                    </MDBCol>
                </MDBRow>
                <MDBRow className="grey-row">
                    <MDBCol size="3" className="d-md-flex d-none">
                        <img src={imageLong} alt="repair-management" className="align-top vertical-image"/>
                    </MDBCol>
                    <MDBCol size="1" className="my-3 d-md-flex d-none justify-content-center vertical-title">
                        <div className="text-center">Services</div>
                    </MDBCol>
                    <MDBCol sm="12" md="8" id="service-icons" className="my-3">
                        <MDBRow>
                            <MDBCol size="3" md="2" className="d-flex align-items-center justify-content-center pe-0">
                                <img src={networkIcon} alt="network"/>
                            </MDBCol>
                            <MDBCol size="9" md="10">
                                <div className="m-4">
                                    <h4>Network</h4>
                                    <p>Our nationwide network of approved repairers has been developed and expanded to
                                        provide a high quality of service from conventional car repairs to heavy
                                        commercials, trailers and motorcycles. We actively manage our network to ensure
                                        strong coverage across the whole of the UK and adapt it to meet the
                                        ever-changing demands of the motor insurance trade.</p>
                                </div>
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol size="3" md="2" className="d-flex align-items-center justify-content-center pe-0">
                                <img src={recoveryIcon} alt="recovery"/>
                            </MDBCol>
                            <MDBCol size="9" md="10">
                                <div className="m-4">
                                    <h4>Recovery</h4>
                                    <p>24 hour UK and European recovery is provided through our trusted service
                                        partners. Covering small vehicle to heavy commercials we can offer enhanced
                                        recovery options including specialist lifting, load transfer and site
                                        management. Agreed robust service levels ensure speedy recovery at cost
                                        effective rates.</p>
                                </div>
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol size="3" md="2" className="d-flex align-items-center justify-content-center pe-0">
                                <img src={qualityIcon} alt="quality"/>
                            </MDBCol>
                            <MDBCol size="9" md="10">
                                <div className="m-4">
                                    <h4>Quality</h4>
                                    <p>All repairs are guaranteed for a minimum of three years. Dedicated network repair
                                        managers ensure quality of repairs are maintained and our network garages meet
                                        the approved manufacturing standards. Our in-house engineers regularly evaluate
                                        our approved garages to strengthen working practices and ensure they meet our
                                        highest standards.</p>
                                </div>
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol size="3" md="2" className="d-flex align-items-center justify-content-center pe-0">
                                <img src={windscreenIcon} alt="windscreen"/>
                            </MDBCol>
                            <MDBCol size="9" md="10">
                                <div className="m-4">
                                    <h4>Windscreen</h4>
                                    <p>Windscreen technology has evolved greatly since the introduction of smart
                                        technology and quality of life automation. Our suppliers provide both repair and
                                        replacement glass services for a wide range of vehicles including specialist
                                        installations and advanced windscreen technologies. </p>
                                </div>
                            </MDBCol>
                        </MDBRow>
                    </MDBCol>
                </MDBRow>
                <ContactBar/>
            </MDBContainer>
        </Styles>
    )
}
