import React from 'react';
import styled from 'styled-components';
import {MDBContainer, MDBRow, MDBCol, MDBIcon} from 'mdb-react-ui-kit';
import Breadcrumb from '../components/Breadcrumb';

const Styles = styled.div`
`;

export default function Complaints() {
    const title = 'Complaints';

    return (
        <Styles>
            <MDBContainer fluid>
                <Breadcrumb active={title}/>
                <MDBRow>
                    <MDBCol size="12">
                        <div className="m-5 info-only">
                            <h2>Complaints</h2>
                            <h4>Our Complaints Procedure</h4>
                            <p>We are committed to providing a high level of customer service. If you feel we have not
                                delivered this, we would welcome the opportunity to put things right for you.</p>
                            <h4>Who to Contact in the First Instance</h4>
                            <p>Many concerns can be resolved straight away. If your complaint relates to the sale or
                                administration of your policy, in the first instance please get in touch with your
                                broker. Contact details will be provided on correspondence that we or our
                                representatives have sent you. (For example, on your welcome or renewal communication or
                                on claim acknowledgement letters.)</p>
                            <p>If your complaint is about a claim, then you can contact us by phone on 01702 444389 or
                                email<a href="mailto:complaints@aaconline.co.uk"> complaints@aaconline.co.uk</a>.</p>
                            <p>Alternatively, you can write to us at:</p>
                            <div className="address">
                                <p>Complaints</p>
                                <p>Auto Accident Claims Ltd</p>
                                <p>7 Argent Court Sylvan Way</p>
                                <p>Basildon</p>
                                <p>Essex</p>
                                <p>SS15 5TH</p>
                            </div>
                            <br/>
                            <p>Many complaints can be resolved within a few days of receipt.</p>
                            <p>If we can resolve your complaint to your satisfaction within the first few days of
                                receipt, we will do so. Otherwise, we will keep you updated with progress and will
                                provide you with our decision as quickly as possible.</p>
                            <h4>Next Steps if You Are Still Unhappy</h4>
                            <p>If you are not happy with the outcome of your complaint, you may be able to ask the
                                Financial Ombudsman Service to review your case.</p>
                            <p>We will let you know if we believe the ombudsman service can consider your complaint when
                                we provide you with our decision. The service they provide is free and impartial, but
                                you would need to contact them within 6 months of the date of our decision.</p>
                            <p>More information about the ombudsman and the type of complaints they can review is
                                available via their website
                                <a href="https://www.financial-ombudsman.org.uk"> https://www.financial-ombudsman.org.uk</a>.
                            </p>
                            <p>You can also contact them as follows:</p>
                            <ul>
                                <li><b>Post:</b> Financial Ombudsman Service, Exchange Tower, London, E14 9SR</li>
                                <li><b>Telephone:</b> 08000 234567 (free on mobile phone and landlines)</li>
                                <li><b>Email:</b>
                                    <a href="mailto:complaint.info@financial-ombudsman.org.uk"> complaint.info@financial-ombudsman.org.uk</a>
                                </li>
                            </ul>
                            <p>If the Financial Ombudsman Service is unable to consider your complaint, you may wish to
                                obtain advice from Citizens Advice (or a similar service) or seek legal advice.</p>

                        </div>
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
        </Styles>
    )
}
