import React, {useState} from 'react';
import styled from 'styled-components';
import {NavDropdown} from 'react-bootstrap';
import {
    MDBNavbar, MDBNavbarNav, MDBNavbarItem, MDBNavbarLink, MDBNavbarBrand, MDBNavbarToggler, MDBContainer, MDBIcon,
    MDBRow, MDBCol, MDBCollapse,
} from 'mdb-react-ui-kit';
import logo from '../assets/images/AAC-Logo-Solid.min.svg';

const Styles = styled.div`
    header {
        nav {
            background-color: var(--aac-blue);
            border-bottom: 1px solid var(--mdb-gray);

            .navbar-brand {
                img {
                    width: 60px;
                }
            }
            
            .navbar-toggler {
                i {
                    color: var(--mdb-white);
                }
            }
            
            .navbar-nav .nav-link.active, .navbar-nav .nav-link.show {
                color: var(--mdb-gray-400);
            }

            li > a, li > .dropdown {
                color: var(--mdb-white);
    
                &:hover {
                    color: var(--mdb-gray-400);
                    cursor: pointer;
    
                    .dropdown-menu {
                        display: block;
                    }
                }
            }
    
            .dropdown {
                .dropdown-menu {
                    padding: 0;
                    background-color: var(--aac-blue);
                    
                    @media only screen and (min-width: 992px) {            
                        transform: translate(77px, 44px) !important;
                    }
                    
                    .dropdown-item {
                        height: 2em;
                        padding-bottom: 2.3rem;
    
                        &:hover {
                            outline: none;
                            box-shadow: none;
                            background: none;
                        }
                    }
                }
    
                .services-navigation {
                    width: 44em;
                    padding-bottom: 1em;
                    
                    @media only screen and (max-width: 992px) {            
                         width: 10em;
                    }
                        
                    .row {
                        .parent-services {
                            border-top-left-radius: 0.2rem;
                            border-bottom-left-radius: 0.2rem;
                            background-color: var(--mdb-gray-500);
    
                            a:hover {
                                color: var(--aac-blue);
                            }
                            
                            @media only screen and (max-width: 992px) {            
                                 border-radius: 0.2rem;
                                 background-color: var(--aac-blue);
                                 
                                a:hover {
                                    color: var(--mdb-gray-400);
                                }
                            }
                        }
    
                        a {
                            font-size: var(--mdb-body-font-size);
    
                            &:hover {
                                color: var(--mdb-gray-400);
                            }
                        }
                    }
                }
            }
        }
    }
`;

export default function Header() {
    const [openNav, setOpenNav] = useState(false);

    const [motorDisplay, setMotorDisplay] = useState('display');
    const [motorLink, setMotorLink] = useState('hover');
    const [auditDisplay, setAuditDisplay] = useState('hide');
    const [auditLink, setAuditLink] = useState('no-hover');
    const [technologyDisplay, setTechnologyDisplay] = useState('hide');
    const [technologyLink, setTechnologyLink] = useState('no-hover');

    const showMotorClaims = e => {
        e.preventDefault();
        setMotorDisplay('display');
        setMotorLink('hover');
        setAuditDisplay('hide');
        setAuditLink('no-hover');
        setTechnologyDisplay('hide');
        setTechnologyLink('no-hover');
    };

    const showAuditClaims = e => {
        e.preventDefault();
        setAuditDisplay('display');
        setAuditLink('hover');
        setMotorDisplay('hide');
        setMotorLink('no-hover');
        setTechnologyDisplay('hide');
        setTechnologyLink('no-hover');
    };

    const showTechnologyClaims = e => {
        e.preventDefault();
        setTechnologyDisplay('display');
        setTechnologyLink('hover');
        setAuditDisplay('hide');
        setAuditLink('no-hover');
        setMotorDisplay('hide');
        setMotorLink('no-hover');
    };

    return (
        <Styles>
            <header>
                <MDBNavbar expand="lg" fixed="top">
                    <MDBContainer fluid>
                        <MDBNavbarBrand href="/" className="p-0"><img src={logo} alt="logo"/>
                        </MDBNavbarBrand>
                        <MDBNavbarToggler aria-controls="navbarSupportedContent" aria-expanded="false"
                                          aria-label="Toggle navigation" onClick={() => setOpenNav(!openNav)}>
                            <MDBIcon fas icon="bars"/>
                        </MDBNavbarToggler>
                        <MDBCollapse navbar show={openNav}>
                            <MDBNavbarNav className="mb-2 mb-lg-0 w-auto ms-auto">
                                <MDBNavbarItem>
                                    <MDBNavbarLink aria-current="page" href="/">Home</MDBNavbarLink>
                                </MDBNavbarItem>
                                <MDBNavbarItem>
                                    <MDBNavbarLink href="/about">About</MDBNavbarLink>
                                </MDBNavbarItem>
                                <MDBNavbarItem>
                                    <NavDropdown title="Services" id="nav-dropdown" className="dropdown"
                                                 renderMenuOnMount={true}
                                                 onMouseEnter={(e) => document.getElementById('nav-dropdown').click()}>
                                        <MDBContainer className="services-navigation p-0"
                                                      onMouseLeave={(e) => document.getElementById('nav-dropdown').click()}>
                                            <MDBRow className="d-lg-none">
                                                <MDBCol size="12" className="parent-services">
                                                    <NavDropdown.Item href="/services/motor-claims">
                                                        Motor Claims
                                                    </NavDropdown.Item>
                                                    <NavDropdown.Item href="/services/technology">
                                                        Technology
                                                    </NavDropdown.Item>
                                                    <NavDropdown.Item href="/services/audit">
                                                        Audit
                                                    </NavDropdown.Item>
                                                </MDBCol>
                                            </MDBRow>
                                            <MDBRow className="d-lg-flex d-none">
                                                <MDBCol size="4" sm="4" md="3" className="parent-services">
                                                    <NavDropdown.Item href="/services/motor-claims"
                                                                      onMouseEnter={e => showMotorClaims(e)}
                                                                      className={motorLink}>
                                                        Motor Claims
                                                    </NavDropdown.Item>
                                                    <NavDropdown.Item href="/services/technology"
                                                                      onMouseEnter={e => showTechnologyClaims(e)}
                                                                      className={technologyLink}>
                                                        Technology
                                                    </NavDropdown.Item>
                                                    <NavDropdown.Item href="/services/audit"
                                                                      onMouseEnter={e => showAuditClaims(e)}
                                                                      className={auditLink}>
                                                        Audit
                                                    </NavDropdown.Item>
                                                </MDBCol>
                                                <MDBCol size="8" sm="8" md="9" className={motorDisplay}>
                                                    <MDBRow>
                                                        <MDBCol size="12" sm="12" md="6" className={motorDisplay}>
                                                            <NavDropdown.Item
                                                                href="/services/motor-claims/first-notification-of-loss">
                                                                First Notification of Loss
                                                            </NavDropdown.Item>
                                                            <NavDropdown.Item
                                                                href="/services/motor-claims/repair-management">
                                                                Repair Management
                                                            </NavDropdown.Item>
                                                            <NavDropdown.Item href="/services/motor-claims/engineering">
                                                                Engineering
                                                            </NavDropdown.Item>
                                                        </MDBCol>
                                                        <MDBCol size="12" sm="12" md="6" className={motorDisplay}>
                                                            <NavDropdown.Item
                                                                href="/services/motor-claims/claims-handling">
                                                                Claims Handling
                                                            </NavDropdown.Item>
                                                            <NavDropdown.Item
                                                                href="/services/motor-claims/third-party-intervention">
                                                                Third Party Intervention
                                                            </NavDropdown.Item>
                                                        </MDBCol>
                                                    </MDBRow>
                                                </MDBCol>
                                                <MDBCol size="8" sm="8" md="9" className={technologyDisplay}>
                                                    <MDBRow>
                                                        <MDBCol size="12" sm="12" md="6" className={technologyDisplay}>
                                                            <NavDropdown.Item
                                                                href="/services/technology/claims-management-system">
                                                                Claims Management System
                                                            </NavDropdown.Item>
                                                            <NavDropdown.Item
                                                                href="/services/technology/management-information">
                                                                Management Information
                                                            </NavDropdown.Item>
                                                            <NavDropdown.Item
                                                                href="/services/technology/communication">
                                                                Communication
                                                            </NavDropdown.Item>
                                                        </MDBCol>
                                                    </MDBRow>
                                                </MDBCol>
                                                <MDBCol size="8" sm="8" md="9" className={auditDisplay}>
                                                    <MDBRow>
                                                        <MDBCol size="12" sm="12" md="6" className={auditDisplay}>
                                                            <NavDropdown.Item href="/services/audit/operations">
                                                                Operations
                                                            </NavDropdown.Item>
                                                            <NavDropdown.Item href="/services/audit/infrastructure">
                                                                Infrastructure
                                                            </NavDropdown.Item>
                                                            <NavDropdown.Item
                                                                href="/services/audit/quality-and-compliance">
                                                                Quality and Compliance
                                                            </NavDropdown.Item>
                                                        </MDBCol>
                                                    </MDBRow>
                                                </MDBCol>
                                            </MDBRow>
                                        </MDBContainer>
                                    </NavDropdown>
                                </MDBNavbarItem>
                                <MDBNavbarItem>
                                    <MDBNavbarLink href="/contact">Contact</MDBNavbarLink>
                                </MDBNavbarItem>
                            </MDBNavbarNav>
                        </MDBCollapse>
                    </MDBContainer>
                </MDBNavbar>
            </header>
        </Styles>
    );
}
