import React from 'react';
import styled from 'styled-components';
import {MDBRow, MDBCol, MDBBtn} from 'mdb-react-ui-kit';

const Styles = styled.div`
    #contact-bar {
        h3 {
            font-family: Titillium, Roboto, Helvetica, Arial, sans-serif;
            text-transform: none;
        }
    }
`;

function ContactBar() {
    return (
        <Styles>
            <MDBRow id="contact-bar" className="d-flex align-items-center blue-container">
                <MDBCol sm="12" md="8">
                    <div className="m-5">
                        <h3>Looking to partner with Auto Accident Claims?</h3>
                        <h3>Get in touch with one of the team now!</h3>
                    </div>
                </MDBCol>
                <MDBCol sm="12" md="4" className="d-flex align-items-center justify-content-center">
                    <div className="mb-5 mb-md-0">
                        <MDBBtn color="light" size="lg" tag="a" href="/contact">Contact Us</MDBBtn>
                    </div>
                </MDBCol>
            </MDBRow>
        </Styles>
    );
}

export default ContactBar;
