import React from 'react'
import styled from 'styled-components';
import {MDBContainer, MDBRow, MDBCol, MDBIcon} from 'mdb-react-ui-kit';
import {GoogleMap, useJsApiLoader, Marker} from '@react-google-maps/api';
import Breadcrumb from '../components/Breadcrumb';
import TitleImage from '../components/TitleImage';
import image from '../assets/images/pier.jpg';

const Styles = styled.div`
    margin-bottom: 0.5rem;

    .address {
        p {
            margin-bottom: 0;
        }

        .contact-info {
            padding-top: 20px;
            color: var(--aac-blue);
        }
    }
`;

const containerStyle = {
    //width: '530px',
    height: '400px',
};

const center = {
    lat: 51.5613378387965,
    lng: 0.7156438725479147,
};

export default function Contact() {
    const title = 'Contact';
    const {isLoaded} = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: 'AIzaSyDoNt7PPSprFnjXuhM_t9xSl3fKu2n7018',
    })

    const [map, setMap] = React.useState(null)

    const onLoad = React.useCallback(function callback(map) {
        // const bounds = new window.google.maps.LatLngBounds(center);
        // map.fitBounds(bounds);
        setMap(map);
    }, []);

    const onUnmount = React.useCallback(function callback(map) {
        setMap(null);
    }, [])

    return isLoaded ? (
        <Styles>
            <MDBContainer fluid className="px-0 overflow-hidden">
                <TitleImage image={image} title={title} backgroundPositionY="46%"/>
                <Breadcrumb active={title}/>
                <MDBRow className="mb-3">
                    <MDBCol sm="12" md="6">
                        <div className="ms-sm-5 mt-sm-5 me-sm-5 mb-sm-5 my-md-5 ms-md-5 me-md-0">
                            <GoogleMap
                                mapContainerStyle={containerStyle}
                                center={center}
                                zoom={10}
                                onLoad={onLoad}
                                onUnmount={onUnmount}>
                                <Marker position={center}/>
                                { /* Child components, such as markers, info windows, etc. */}
                                <></>
                            </GoogleMap>
                        </div>
                    </MDBCol>
                    <MDBCol sm="12" md="6">
                        <div className="mx-3 mx-sm-5 mt-sm-0 my-md-5 me-md-5">
                            <MDBRow className="mb-3">
                                <MDBCol size="6">
                                    <div className="address">
                                        <h4>Head Office</h4>
                                        <p>Sureland House</p>
                                        <p>Journeymans Way</p>
                                        <p>Southend-on-Sea</p>
                                        <p>Essex</p>
                                        <p>SS2 5TF</p>
                                        <div className="contact-info">
                                            <p><MDBIcon fas icon="phone"/> +44(0) 345 600 7501</p>
                                            <p><MDBIcon fas icon="envelope"/>
                                                <a href="mailto:info@aaconline.co.uk"> info@aaconline.co.uk</a>
                                            </p>
                                        </div>
                                    </div>
                                </MDBCol>
                                <MDBCol size="6">
                                    <div className="address">
                                        <h4>Basildon Office</h4>
                                        <p>7 Argent Court</p>
                                        <p>Sylvan Way</p>
                                        <p>Basildon</p>
                                        <p>Essex</p>
                                        <p>SS15 6TH</p>
                                        <div className="contact-info">
                                            <p><MDBIcon fas icon="phone"/> +44(0) 345 600 7503</p>
                                        </div>
                                    </div>
                                </MDBCol>
                            </MDBRow>
                        </div>
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
        </Styles>
    ) : <></>
}
