import React from 'react';
import styled from 'styled-components';
import {MDBContainer, MDBRow, MDBCol} from 'mdb-react-ui-kit';
import Breadcrumb from '../../../components/Breadcrumb';
import ContactBar from '../../../components/ContactBar';
import TitleImage from '../../../components/TitleImage';
import image from '../../../assets/images/operations.jpg';
import imageLong from '../../../assets/images/operations-long.jpg';
import structureIcon from '../../../assets/icons/structure.png';
import fileManagementIcon from '../../../assets/icons/file-management.png';
import reservingIcon from '../../../assets/icons/reserving.png';

const Styles = styled.div`
`;

export default function Operations() {
    const title = 'Operations';
    const items = [{name: 'Services', link: '/services'},
        {name: 'Audit', link: '/services/audit'}];

    return (
        <Styles>
            <MDBContainer fluid className="px-0 overflow-hidden">
                <TitleImage image={image} title={title} backgroundPositionY="50%"/>
                <Breadcrumb items={items} active={title}/>
                <MDBRow>
                    <MDBCol size="12">
                        <div className="m-5">
                            <h3><b>Work Smarter Not Harder</b></h3>
                            <p>Our audit services can help you assess the quality of your end-to-end claims handling
                                processes, to ensure they are efficient, effective, and bringing genuine value to your
                                business. We also provide evaluation of your hard and soft leakage with guidance on how
                                to mitigate further potential loss through training, policy review, and automation.</p>
                            <p>On a broader platform we assist in finding the ideal strategy to match your business
                                philosophy, ensure that your resources are best placed to provide a consistent
                                cradle-to-grave service, and deep dive into your reserving practices, from base
                                reserving methods to claim closure rules.</p>
                        </div>
                    </MDBCol>
                </MDBRow>
                <MDBRow className="grey-row">
                    <MDBCol size="3" className="d-md-flex d-none">
                        <img src={imageLong} alt="operations" className="align-top vertical-image"/>
                    </MDBCol>
                    <MDBCol size="1" className="my-3 d-md-flex d-none justify-content-center vertical-title">
                        <div className="text-center">Services</div>
                    </MDBCol>
                    <MDBCol sm="12" md="8" id="service-icons" className="my-3">
                        <MDBRow>
                            <MDBCol size="3" md="2" className="d-flex align-items-center justify-content-center pe-0">
                                <img src={structureIcon} alt="structure"/>
                            </MDBCol>
                            <MDBCol size="9" md="10">
                                <div className="m-4">
                                    <h4>Structure</h4>
                                    <p>A good foundation is key to any business. Through structural auditing we can help
                                        you evaluate your allocation and configuration of resources and provide a
                                        thorough assessment of your operational efficiency. By identifying strengths,
                                        weaknesses, shortfalls and resource imbalance we help to streamline your
                                        organisation and maximise capacity.</p>
                                </div>
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol size="3" md="2" className="d-flex align-items-center justify-content-center pe-0">
                                <img src={fileManagementIcon} alt="file-management"/>
                            </MDBCol>
                            <MDBCol size="9" md="10">
                                <div className="m-4">
                                    <h4>File Management</h4>
                                    <p>Through file sampling and in-depth forensic analysis of your claims processes, we
                                        initially assess adherence to your own processes and procedures. Making use of
                                        our extensive knowledge on current industry standards and comparable business
                                        audits we can then suggest improvements to your established working practices
                                        helping eliminate risks, bottlenecks, and unnecessary overheads.</p>
                                </div>
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol size="3" md="2" className="d-flex align-items-center justify-content-center pe-0">
                                <img src={reservingIcon} alt="reserving"/>
                            </MDBCol>
                            <MDBCol size="9" md="10">
                                <div className="m-4">
                                    <h4>Reserving</h4>
                                    <p>Accurate file reserves are essential to the profitability of any insurer. It is
                                        therefore important that you find the right balance that is neither too
                                        aggressive nor conservative. We can help you evaluate your reserving practices,
                                        including for large and complex losses, to ensure they are adequate, accurate
                                        and in line with industry standards.</p>
                                </div>
                            </MDBCol>
                        </MDBRow>
                    </MDBCol>
                </MDBRow>
                <ContactBar/>
            </MDBContainer>
        </Styles>
    )
}
