import React from 'react';
import styled from 'styled-components';
import {MDBContainer, MDBRow, MDBCol} from 'mdb-react-ui-kit';
import Breadcrumb from '../../../components/Breadcrumb';
import ContactBar from '../../../components/ContactBar';
import TitleImage from '../../../components/TitleImage';
import image from '../../../assets/images/management-information.jpg';
import imageLong from '../../../assets/images/management-information-long.jpg';
import performanceIcon from '../../../assets/icons/performance.png';
import analyticsIcon from '../../../assets/icons/analytics.png';
import customisationIcon from '../../../assets/icons/customisation.png';
import feedIcon from '../../../assets/icons/feed.png';

const Styles = styled.div`
`;

export default function ManagementInformation() {
    const title = 'Management Information';
    const items = [{name: 'Services', link: '/services'},
        {name: 'Technology', link: '/services/technology'}];

    return (
        <Styles>
            <MDBContainer fluid className="px-0 overflow-hidden">
                <TitleImage image={image} title={title} backgroundPositionY="50%"/>
                <Breadcrumb items={items} active={title}/>
                <MDBRow>
                    <MDBCol size="12">
                        <div className="m-5">
                            <h3><b>Insights That Matter</b></h3>
                            <p>Management information is a crucial aspect of any organization. It provides the necessary
                                data and insights to make informed decisions, optimize processes, and improve overall
                                performance. With the right management information system, you can streamline your
                                operations, reduce costs, and increase efficiency.</p>
                            <p>Our service is designed to be user-friendly and customizable to your specific needs.
                                Tailored to both you and your internal customers, we offer a range of features,
                                including high level portfolio analysis, performance monitoring, and granular breakdowns
                                of expenditure.</p>
                        </div>
                    </MDBCol>
                </MDBRow>
                <MDBRow className="grey-row">
                    <MDBCol size="3" className="d-md-flex d-none">
                        <img src={imageLong} alt="management-information" className="align-top vertical-image"/>
                    </MDBCol>
                    <MDBCol size="1" className="my-3 d-md-flex d-none justify-content-center vertical-title">
                        <div className="text-center">Services</div>
                    </MDBCol>
                    <MDBCol sm="12" md="8" id="service-icons" className="my-3">
                        <MDBRow>
                            <MDBCol size="3" md="2" className="d-flex align-items-center justify-content-center pe-0">
                                <img src={performanceIcon} alt="performance"/>
                            </MDBCol>
                            <MDBCol size="9" md="10">
                                <div className="m-4">
                                    <h4>Performance</h4>
                                    <p>Our real-time dashboards visualize claim metrics, KPI performance, and
                                        bottlenecks so that our agents can react to changing demands. Comprehensive
                                        performance packs delve into historical claims data, identifying patterns,
                                        outliers, and trends. With this information we can track claim resolution times,
                                        customer satisfaction, and cost efficiency.</p>
                                </div>
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol size="3" md="2" className="d-flex align-items-center justify-content-center pe-0">
                                <img src={analyticsIcon} alt="analytics"/>
                            </MDBCol>
                            <MDBCol size="9" md="10">
                                <div className="m-4">
                                    <h4>Analytics</h4>
                                    <p>Data analytics, including bordereaux analysis, is a crucial tool for insurers.
                                        With data analytics, you can uncover patterns, assess risks, and make informed
                                        decisions. Whether it is evaluating loss ratios, spotting trends in claims, or
                                        ensuring portfolio health, data analytics provides actionable insights. We
                                        provide a wide range of business information products covering every aspect of
                                        the claim cycle including repair costs, claims breakdown and hire risk.</p>
                                </div>
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol size="3" md="2" className="d-flex align-items-center justify-content-center pe-0">
                                <img src={customisationIcon} alt="customisation"/>
                            </MDBCol>
                            <MDBCol size="9" md="10">
                                <div className="m-4">
                                    <h4>Customisation</h4>
                                    <p>A custom business information analytics solution empowers your organization to
                                        make data-driven decisions with precision. By tailoring analytics to your unique
                                        needs, we unlock actionable insights that drive growth. Our team of experts
                                        crafts bespoke solutions, integrating data from diverse sources, analysing it
                                        rigorously, and presenting it elegantly.</p>
                                </div>
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol size="3" md="2" className="d-flex align-items-center justify-content-center pe-0">
                                <img src={feedIcon} alt="feed"/>
                            </MDBCol>
                            <MDBCol size="9" md="10">
                                <div className="m-4">
                                    <h4>Feed</h4>
                                    <p>We understand that our clients will wish to perform their own in-house analysis
                                        and we make sure the raw data is available in a format that works for you.
                                        Whether you need it in XML, CSV or via an API integration, we can adjust to fit
                                        any interface.</p>
                                </div>
                            </MDBCol>
                        </MDBRow>
                    </MDBCol>
                </MDBRow>
                <ContactBar/>
            </MDBContainer>
        </Styles>
    )
}
