import React from 'react';
import styled from 'styled-components';
import {MDBContainer, MDBRow, MDBCol} from 'mdb-react-ui-kit';
import Breadcrumb from '../../../components/Breadcrumb';
import ContactBar from '../../../components/ContactBar';
import TitleImage from '../../../components/TitleImage';
import image from '../../../assets/images/fnol.jpg';
import imageLong from '../../../assets/images/fnol-long.jpg';
import callMonitoringIcon from '../../../assets/icons/call-monitoring.png';
import claimJourneyIcon from '../../../assets/icons/claim-journey.png';
import peakManagementIcon from '../../../assets/icons/peak-management.png';
import internalCustomersIcon from '../../../assets/icons/internal-customers.png';
import outOfHoursIcon from '../../../assets/icons/out-of-hours.png';

const Styles = styled.div`  
     @media only screen and (max-width: 895px) {
        .bg-image {
            background-position-y: 100% !important;
        }
    }
`;

export default function FirstNotificationOfLoss() {
    const title = 'First Notification of Loss';
    const items = [{name: 'Services', link: '/services'},
        {name: 'Motor Claims', link: '/services/motor-claims'}];

    return (
        <Styles>
            <MDBContainer fluid className="px-0 overflow-hidden">
                <TitleImage image={image} title={title} backgroundPositionY="64%"/>
                <Breadcrumb items={items} active={title}/>
                <MDBRow>
                    <MDBCol size="12">
                        <div className="m-5">
                            <h3><b>The Journey Begins Here</b></h3>
                            <p>We understand first impressions mean everything to your customers, especially during
                                difficult times. AAC provides guidance through every stage of a claim life cycle to
                                ensure a seamless and pain free experience. Our staff are trained to capture key
                                information and determine the most appropriate action required, be it collection and
                                settlement of a potential total loss or the immediate instruction of an approved
                                repairer or nominated garage.</p>
                            <p>Our fully white-labelled 24/7/365 contact centre is equipped to receive new claims
                                through a variety of channels including web-based submissions, email, telephony, and
                                client platform integration. Always on hand night and day, we are there to keep your
                                customers moving and make sure your brand is at the forefront of first-class customer
                                service.</p>
                        </div>
                    </MDBCol>
                </MDBRow>
                <MDBRow className="grey-row">
                    <MDBCol size="3" className="d-md-flex d-none">
                        <img src={imageLong} alt="fnol" className="align-top vertical-image"/>
                    </MDBCol>
                    <MDBCol size="1" className="my-3 d-md-flex d-none justify-content-center vertical-title">
                        <div className="text-center">Services</div>
                    </MDBCol>
                    <MDBCol sm="12" md="8" id="service-icons" className="my-3">
                        <MDBRow>
                            <MDBCol size="3" md="2" className="d-flex align-items-center justify-content-center pe-0">
                                <img src={callMonitoringIcon} alt="call-monitoring"/>
                            </MDBCol>
                            <MDBCol size="9" md="10">
                                <div className="m-4">
                                    <h4>Call Monitoring</h4>
                                    <p>Top quality customer service is a core principle at AAC. All methods of
                                        communication are monitored and regularly reviewed to ensure adherence to both
                                        industry standards and our own premier benchmarks. Your reputation is our
                                        reputation, so we work in partnership with your quality ambassadors to elevate
                                        your public profile and keep those five-star reviews pouring in.</p>
                                </div>
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol size="3" md="2" className="d-flex align-items-center justify-content-center pe-0">
                                <img src={claimJourneyIcon} alt="claim-journey"/>
                            </MDBCol>
                            <MDBCol size="9" md="10">
                                <div className="m-4">
                                    <h4>Claim Journey</h4>
                                    <p>Our proactive end-to-end guided experience uses the latest in communication
                                        technology to keep your policyholders in the loop every step of the way. We know
                                        time is precious to your customers, so we are consistently exploring new methods
                                        to deliver great service while fitting in around a modern working lifestyle.</p>
                                </div>
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol size="3" md="2" className="d-flex align-items-center justify-content-center pe-0">
                                <img src={peakManagementIcon} alt="peak-management"/>
                            </MDBCol>
                            <MDBCol size="9" md="10">
                                <div className="m-4">
                                    <h4>Peak Management</h4>
                                    <p>The insurance landscape is constantly changing, and we make sure to evolve with
                                        it. Through multi-skilled advisors, pre-emptive peak predictors, and our modern
                                        flexible working practices we are ready for the planned peaks and adaptable to
                                        the unplanned ones.</p>
                                </div>
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol size="3" md="2" className="d-flex align-items-center justify-content-center pe-0">
                                <img src={internalCustomersIcon} alt="internal-customers"/>
                            </MDBCol>
                            <MDBCol size="9" md="10">
                                <div className="m-4">
                                    <h4>Internal Customers</h4>
                                    <p>We understand that every client interaction is important. By building
                                        relationships with your brokers, experts panel and appointed TPAs, we present a
                                        unified service to your policyholders. Our tailored MI services can provide
                                        bespoke reporting giving both you and your chosen partners the tools to grow
                                        together.</p>
                                </div>
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol size="3" md="2" className="d-flex align-items-center justify-content-center pe-0">
                                <img src={outOfHoursIcon} alt="out-of-hours"/>
                            </MDBCol>
                            <MDBCol size="9" md="10">
                                <div className="m-4">
                                    <h4>Out of Hours</h4>
                                    <p>Accidents do not wait for a convenient time to happen. Our contact services run
                                        24/7/365 so that your policyholders are always supported. Whenever, wherever, we
                                        will get them home safe and sound.</p>
                                </div>
                            </MDBCol>
                        </MDBRow>
                    </MDBCol>
                </MDBRow>
                <ContactBar/>
            </MDBContainer>
        </Styles>
    )
}
