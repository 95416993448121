import React from 'react';
import styled from 'styled-components';
import {MDBContainer, MDBRow, MDBCol} from 'mdb-react-ui-kit';
import Breadcrumb from '../../../components/Breadcrumb';
import ContactBar from '../../../components/ContactBar';
import TitleImage from '../../../components/TitleImage';
import image from '../../../assets/images/claims-handling.jpg';
import imageLong from '../../../assets/images/claims-handling-long.jpg';
import creditHireIcon from '../../../assets/icons/credit-hire.png';
import injuryIcon from '../../../assets/icons/injury.png';
import fireAndTheftIcon from '../../../assets/icons/fire-and-theft.png';
import fraudIcon from '../../../assets/icons/fraud.png';
import uninsuredLossRecoveryIcon from '../../../assets/icons/uninsured-loss-recovery.png';

const Styles = styled.div`
`;

export default function ClaimsHandling() {
    const title = 'Claims Handling';
    const items = [{name: 'Services', link: '/services'},
        {name: 'Motor Claims', link: '/services/motor-claims'}];

    return (
        <Styles>
            <MDBContainer fluid className="px-0 overflow-hidden">
                <TitleImage image={image} title={title} backgroundPositionY="50%"/>
                <Breadcrumb items={items} active={title}/>
                <MDBRow>
                    <MDBCol size="12">
                        <div className="m-5">
                            <h3><b>Building Your Reputation</b></h3>
                            <p>With a wealth of knowledge in motor insurance, we adopt a proactive approach to claims
                                handling. We pride ourselves on our excellent fraud detection, theft investigation and
                                total loss handling while ensuring enviable cost reduction, savings and a robust market
                                image. We see our claims handling as a long-term partnership and are able to tailor our
                                service to individual requirements or specific policy limitations. When dealing with
                                sensitive cases that may attract media attention, such as fatalities or incidents where
                                serious injuries are sustained, we bring an experienced level headed and empathic
                                approach. A proactive delivery is adopted to aid the claimant's recovery and bring the
                                case to a conclusion. While we aim to resolve cases by negotiation, if court proceedings
                                are issued then we are versed in attending settlement meetings and the use of
                                mediation.</p>
                        </div>
                    </MDBCol>
                </MDBRow>
                <MDBRow className="grey-row">
                    <MDBCol size="3" className="d-md-flex d-none">
                        <img src={imageLong} alt="claims-handling" className="align-top vertical-image"/>
                    </MDBCol>
                    <MDBCol size="1" className="my-3 d-md-flex d-none justify-content-center vertical-title">
                        <div className="text-center">Services</div>
                    </MDBCol>
                    <MDBCol sm="12" md="8" id="service-icons" className="my-3">
                        <MDBRow>
                            <MDBCol size="3" md="2" className="d-flex align-items-center justify-content-center pe-0">
                                <img src={creditHireIcon} alt="credit-hire"/>
                            </MDBCol>
                            <MDBCol size="9" md="10">
                                <div className="m-4">
                                    <h4>Credit Hire</h4>
                                    <p>Working in conjunction with our FNOL intervention team, we proactively manage and
                                        negotiate on credit hire cases and vigorously defend where the offer to provide
                                        an alternative vehicle is declined. By drawing on our wealth of MI, we can
                                        provide a clear view of the credit hire landscape to develop new strategies for
                                        containing expenditure.</p>
                                </div>
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol size="3" md="2" className="d-flex align-items-center justify-content-center pe-0">
                                <img src={injuryIcon} alt="injury"/>
                            </MDBCol>
                            <MDBCol size="9" md="10">
                                <div className="m-4">
                                    <h4>Injury</h4>
                                    <p>Our technical claims team deals with all aspects of personal injury including
                                        portal notifications and multi-track cases, as well as cases received via
                                        Official Injury Claims (OIC). Through accurate diary processes and proactive
                                        handling, we consistently mitigate leakage for your clients. On high value and
                                        complex claims we work seamlessly with your panel of solicitors to ensure they
                                        are fully prepared on every case.</p>
                                </div>
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol size="3" md="2" className="d-flex align-items-center justify-content-center pe-0">
                                <img src={fireAndTheftIcon} alt="fire-and-theft"/>
                            </MDBCol>
                            <MDBCol size="9" md="10">
                                <div className="m-4">
                                    <h4>Fire and Theft</h4>
                                    <p>We offer a tiered approach on specialist cases such as theft and fire, ensuring
                                        valid claims are processed promptly and those giving cause for concern are
                                        investigated accordingly. We coordinate with recognised investigative service
                                        providers where claims warrant a deeper level of scrutiny.</p>
                                </div>
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol size="3" md="2" className="d-flex align-items-center justify-content-center pe-0">
                                <img src={fraudIcon} alt="fraud"/>
                            </MDBCol>
                            <MDBCol size="9" md="10">
                                <div className="m-4">
                                    <h4>Fraud</h4>
                                    <p>As members of IFIG, we recognise fraudsters are becoming more sophisticated.
                                        Using the latest fraud intelligence and our in-house indicators, we strive to
                                        stop fraudulent claims in their tracks. Fraud training is delivered at every
                                        aspect of our service to maximise our potential for catching suspicious
                                        cases.</p>
                                </div>
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol size="3" md="2" className="d-flex align-items-center justify-content-center pe-0">
                                <img src={uninsuredLossRecoveryIcon} alt="uninsured-loss-recovery"/>
                            </MDBCol>
                            <MDBCol size="9" md="10">
                                <div className="m-4">
                                    <h4>Uninsured Loss Recovery</h4>
                                    <p>The importance of recovering uninsured losses for clients cannot be
                                        over-stressed. We offer a well-developed process with a high success rate to
                                        advise, assure and ultimately reimburse the customer in a timely manner.</p>
                                </div>
                            </MDBCol>
                        </MDBRow>
                    </MDBCol>
                </MDBRow>
                <ContactBar/>
            </MDBContainer>
        </Styles>
    )
}
